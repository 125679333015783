Number.prototype.toPrettyFormat = function () {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}




Array.prototype.filterRecursive = function(predicate, childProperty){
    let filterResults = [];
    let filterAndPushResults = (arrayToFilter)=>{
        let elementsWithChildren  = arrayToFilter.filter(x=>x !== undefined).filter(x=>x[childProperty]);
        let filtered = arrayToFilter.filter(predicate);
        filterResults.push(...filtered);
        if(elementsWithChildren.length){
            let childElements = [];
            elementsWithChildren.forEach(x=>{
                childElements.push(...x[childProperty]);
            });
            filterAndPushResults(childElements);
        }
    };
    filterAndPushResults(this);
    return filterResults;
}

Array.prototype.intersect = function (b) {
    return this.filter(value => b.includes(value));
}

Array.prototype.diff = function (b) {
    return this.filter(value => !b.includes(value));
}

Array.prototype.unique = function () {
    return Object.keys(Object.fromEntries(this.map(x => [x])))
}

String.prototype.slug = function () {
    return this .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}


Object.clearObject = (obj) => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([, b]) => !!b)
            .map(([a, b]) => {

                if(b instanceof Object && !Array.isArray(b) && !(b instanceof Date))
                    b = Object.clearObject(b);

                return [a,b];
            })
    );
}
Object.isEqual = function(o1, o2){

    for (let p in {...o1, ...o2}) {

        if(!o1.hasOwnProperty(p)) return false;
        if(!o2.hasOwnProperty(p)) return false;

        if(o1[p] instanceof Object && !Array.isArray(o1[p]) && o2[p] instanceof Object && !Array.isArray(o2[p]))
            if(Object.isEqual(o1[p], o2[p])) continue; else return false;

        if(o1[p] !== o2[p])
            return false;

    }

    return true;
};

String.prototype.trimChar = function (char)
{
    const charRegex = char.replace(/[.*+?^${}()\/|[\]\\]/g, '\\$&');

    return this.replace(new RegExp(`^${charRegex}+`), '').replace(new RegExp(`${charRegex}+$`), '');
}

Date.prototype.subMonths = function (months) {

    this.setMonth(this.getMonth() - months);

    return this;
}
RegExp.validatePesel = function (pesel)
{
    const reg = /^[0-9]{11}$/;
    if(reg.test(pesel) === false)
        return false;
    else
    {
        let digits = (""+pesel).split("");
        if ((parseInt(pesel.substring( 4, 6)) > 31)||(parseInt(pesel.substring( 2, 4)) > 12))
            return false;

        let checksum = (parseInt(digits[0]) + 3*parseInt(digits[1]) + 7*parseInt(digits[2]) + 9*parseInt(digits[3]) + 1*parseInt(digits[4]) + 3*parseInt(digits[5]) + 7*parseInt(digits[6]) + 9*parseInt(digits[7]) + 1*parseInt(digits[8]) + 3*parseInt(digits[9]))%10;
        if(checksum===0) checksum = 10;
        checksum = 10 - checksum;

        return (parseInt(digits[10])===checksum);
    }
}
RegExp.validatePhone = function (phone)
{
    return /^(\+?\d{2})?(45|50|51|53|57|60|66|69|72|73|78|79|88)\d{7}$/.test(phone);
}

RegExp.validateEmail = function (mail)
{
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)
}

Array.range = function (a,b)
{
    return [...Array(b-a+1).keys()].map(x => a+x);
}