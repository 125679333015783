<template>
    <div class="d-flex">
         <div class="form-control-image me-3">
             <img :src="value" ref="image" :class="{[$props['class']]: !!$props['class']}" alt=""/>
         </div>
        <div class="flex-grow-1">
            <p class="py-2 mb-0 fs-normal">
                {{ description }}
            </p>
            <button class="btn position-relative cursor-pointer" :class="{[this.btnColorClass]: !!this.btnColor, [btnSizeClass]: !!this.btnSize}">
                <span v-if="loading" class="spinner-border spinner-border-sm me-2"   role="status">
                    <span class="visually-hidden"></span>
                </span>
                <v-icon v-else-if="btnIcon" :icon="btnIcon" class="pe-2" />
                <span>{{ btnLabel }}</span>
                <input ref="control" autocomplete="off" type="file" class="file-float-control" @change="attach"/>
            </button>
            <p class="form-control-error text-danger" :key="msg" v-for="msg in error">błąd: {{ msg }}</p>
        </div>
    </div>
</template>

<script>
import control from "@/mixins/control";

export default {
    name: "VControlImage",
    mixins: [control],
    inheritAttrs: false,
    emits: ['attach'],
    props: {
        multiple: {default: false},
        loading: {},
        class: {},
        btnColor: {},
        btnOutline: {},
        btnSize: {},
        btnIcon: {},
        btnLabel: {},
        description: {}
    },
    methods: {
        attach(e) {

            this.$emit('attach', this.multiple ? e.target.files : e.target.files[0]);
        }
    },
    computed: {
        btnSizeClass() {
            return ["btn", this.btnSize].join("-");
        },
        btnColorClass() {
            return ["btn", this.btnOutline ? "outline" : null, this.btnColor]
                .filter((x) => !!x)
                .join("-");
        }
    }

}
</script>
