export default  {
    computed: {
        agentsOptions() {
            return async () => {
                return this.$http.request({url:'agents'})
                    .then((response) => {
                        return [{value: '', text: '-- wybierz --'}].concat(response.data.agents.map((user) => {
                            return {
                                value: `${user.id}`,
                                text: user.firstName ? [user.firstName,user.lastName].join(' ') : user.username
                            }
                        }));
                    });
            }


        }
    }
}