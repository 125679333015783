import model from "@/mixins/model";
import message from "@/shared/chat/message";

export default {
    mixins: [model],
    data() {
        return {
            isBackground: true,
            connection: null,
            conversations: {},
            clients: [],
            events: {}
        }
    },
    provide(){
        return {
            chat: this
        }
    },
    model() {
        return {
            popups: {}
        }
    },

    beforeUnmount() {
        this.off('conversation.push', this.pushConversation)
        this.off('conversation.all', this.allConversation)
        this.off('open', this.fetchConversation);
    },
    mounted() {

        this.on('conversation.push', this.pushConversation);
        this.on('conversation.all', this.allConversation);
        this.onOpen(this.fetchConversation);

        this.on('message.push', (message) => {
            if(this.isBackground && !message.me) this.popupMessage(message);
        });

        if(this.$auth.permissions.mobileEnabled)
            this.connect();
    },
    computed: {
        countUnseenConversations() {
            return this.clients.filter(item => this.conversations[item.uid]?.countUnreadMessages).length;
        },
        conversationUid() {
            return Object.keys(this.conversations);
        },
        popupsMessages() {
          return Object.values(this.model.popups);
        },
        isConnected() {
            if(!this.connection)
                return false;

            return this.connection.isConnected;
        },
    },
    methods: {
        fetchConversation() {
            this.send('conversation.fetch', {});
        },
        popupMessage(message) {
            this.$http.request({url: 'agent/chat-contacts/'+message.authorId})
                .then((response) => {
                    this.model.popups[response.data.client.uid] = {...message, ...response.data.client}

                    setTimeout(() => {
                        delete this.model.popups[response.data.client.uid];
                    }, 5000);
                })
        },
        addConversation(conversation) {

            if(conversation.lastSendAt)
                conversation.lastSendAt = new Date(conversation.lastSendAt);

            return this.conversations[conversation.uid] = Object.assign(this.conversations[conversation.uid] ?? {bind: false}, conversation);
        },
        pushConversation(conversation) {
            this.addConversation(conversation);
            this.fetch(true)
        },
        allConversation(payload) {

            payload.conversations.forEach((conversation) => {
                this.addConversation(conversation);
            });

            this.fetch(true)

        },
        background(state) {
            this.isBackground = state;
        },
        off(signature, callback) {
            if(!this.events[signature]) return;

            const idx = this.events[signature].indexOf(callback)

            if(idx >= 0)
                this.events[signature].splice(idx, 1);
        },
        on(signature, callback) {

            if(!this.events[signature])
                this.events[signature] = [];


            this.events[signature].push(callback);
        },
        onOpen(callback) {

            this.on('open', callback);

            if(this.isConnected) callback();
        },
        send(signature,data) {

            if(!this.isConnected)
                throw new Error('No connection ws');

            this.connection.send(signature,data);
        },
        reconnect(attempt = 1)
        {
            setTimeout(() => this.connect(attempt+1), 5000);
        },
        dispatchEvent(message) {
            if(this.events[message.signature])
                return this.events[message.signature].forEach((c) => c(message));

        },
        fetch(silent = false) {

            return this.dispatch((resolve, reject) => {

                if(silent) this.loading = false;

                if(!this.conversationUid.length) {
                    this.clients = [];
                    return resolve();
                }


                return this.$http.request({url: 'agent/chat-contacts', params:  {uid: {in: this.conversationUid}, show: this.conversationUid.length}})
                    .then((response) => {

                        this.clients = response.data.clients;

                    })
                    .then(resolve)
                    .catch(reject)
            });
        },
        connect(attempt = 1) {

            this.$http.request({
                name: 'leado', url: 'integrations/wniosker/user/auth', method: 'post',
                data: {
                    token: this.$auth.getAuthToken()
                }
            })
                .then((response) => {

                    this.connection = this.$ws.connect({name:'leado',url:'/consultant/chat', query: {
                        token: 1 ? response.data.token : 'aa'
                    }, reconnect: { attempt: attempt }})
                        .open(() => {
                            attempt = 1;

                          this.dispatchEvent({signature:'open'});

                        })
                        .error((e) => {

                        })
                        .message((message) => {

                           this.dispatchEvent(message);

                        })
                        .close(() => {
                            this.reconnect(attempt)
                        })



                })
                .catch((e) => {
                    console.log(e)
                })
        }

    }
}