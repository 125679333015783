<template>
    <div class="mb-2">
        <div class="progress" style="height: 5px; margin-bottom: 5px">
            <div class="progress-bar" :class="{['bg-'+color]: color}" role="progressbar" :style="{width: strength + '%'}" ></div>
        </div>
        <small :class="{['text-'+color]:color}">
            Poziom hasła: {{ label }}
        </small>
        <p class="mb-0 vert-middle"  :class="{'text-success': digit, 'text-dark': !digit}"><i class="icon-check-line"></i> Przynajmiej JEDNĄ cyfrę</p>
        <p class="mb-0 vert-middle"  :class="{'text-success': upperCase, 'text-dark': !upperCase}"><i class="icon-check-line"></i> Przynajmiej JEDNĄ dużą literę</p>
        <p class="mb-0 vert-middle" :class="{'text-success': length8, 'text-dark': !length8}"><i class="icon-check-line"></i> Przynajmiej 8 znaków</p>
    </div>

</template>

<script>

import component from "../mixins/component";


export default {
    mixins: [component],
    name: "StrengthPassword",
    props: {
        password: String
    },
    computed: {
        label() {

            if(this.strength >= 80)
                return 'Wysoki';

            if(this.strength >= 40)
                return 'Średni';

            if(this.password)
                return 'Niski';

            return 'Brak';
        },
        color() {

            if(this.strength >= 80)
                return 'success';

            if(this.strength >= 40)
                return 'warning';

            if(this.password)
                return 'danger';

            return null;
        },
        strength() {
            return ((this.digit * 30) + (this.upperCase * 40) + (this.length8 * 30)) ;
        },
        digit() {
            return /\d+/.test(this.password);
        },
        upperCase() {
            return /[A-Z]+/.test(this.password);
        },
        length8() {
            return this.password?.length >= 8;
        }
    }
}
</script>
