import model from "@/mixins/model";

export default {
    mixins: [model],
    query() {
        return {
            show: 50,
            page: 1,
        }
    },
    model() {
        return {
            applications: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {

            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'leads/:lead/applications', params: this.query  })
                    .then((response) => {

                        this.model.applications = response.data.applications;
                        this.pagination = response.data.pagination;
                    })
                    .then(resolve)
                    .catch(reject);

            });


        },
    }
}