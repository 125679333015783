import model from "@/mixins/model";

export default  {
    mixins: [model],
    model() {
        return {
            avatar: null
        }
    },
    mounted() {
        this.model.avatar = this.$auth.user.avatar;
    },
    methods: {
        attach(file) {

            const formData = new FormData();
            formData.append('avatar', file);

            return this.dispatch((resolve, reject) => {
            //
                this.$http.request({ url: 'agent/avatar', method: 'post', data: formData })
                    .then((response) => {

                        const timestamp = new Date().getTime();

                        this.model.avatar+='?='+timestamp;
                        this.$auth.user.avatar =  this.model.avatar
                    })
                    .then(resolve)
                    .catch(reject);
            //
            }).catch(() => {});

        }
    }
}