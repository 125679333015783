import model from '../mixins/model';
import moment from "moment/moment";

export default {
    mixins: [model],
    model() {
        return {
            clients: [],
            pagination: {}
        }
    },
    query() {
        return {
            show: 50,
            from_at: null,
            leadAccepted: null,
            leadRedirected: null,
            hasMobileApp: null,
            'created_at': {start: null, end: null},
            'personId': { equal: null },
            'email': { equal: null },
            'phone': { equal: null },
            'firstName': { equal: null },
            'lastName': { equal: null },
            'status_id': { in: [] }
        }
    },
    mounted() {
        this.bindQuery();
        this.fetch();
    },
    watch: {
        'query.from_at': {
            handler() {
                let start = moment().startOf('day');

                switch (this.query.from_at) {
                    case '7days':
                        start =  start.subtract(7, 'days').utc().format();
                        break;
                    case '3days':
                        start = start.subtract(3, 'days').utc().format();
                        break;
                    case 'yesterday':

                        start = start.subtract(1, 'days').utc().format();
                        break;
                    case 'today':
                        start = start.utc().format();
                        break;
                    case 'custom':
                    default:
                        start = null;
                        break;
                }

                this.query.created_at = {start,end:null};

            }
        },
        '$globalQueries.params.agent_id': {
            handler(newValue, oldValue) {

                if(oldValue !== undefined) this.fetch()
            },
            deep: true
        }
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {

                this.$router.replace({query: this.query}).then(() => {
                    this.$http.request({ url: 'clients', params: {...this.query, agent_id: this.$globalQueries.params.agent_id}})
                        .then((response) => {

                            this.model.clients = response.data.clients;
                            this.pagination = response.data.pagination;
                        })
                        .then(resolve)
                        .catch(reject);
                })



            });


        }
    }
}