<template>
    <section>
        <div class="d-flex justify-content-between">
            <div class="hgroup">
                <h1>Materiały promocyjne</h1>
                <h2>Agencie! Promuj się lokalnie za pomocą unikalnych gotowych materiałów!</h2>
            </div>
        </div>

                <v-model.promotions v-slot="{copyTemplateMail,contentHtmlMail,socialBannerDownload,copyTemplateFB, copyTemplateSMS, model,socialMainBanner,socialCardBanner, qrCodeAd, qrCodeAdToPdf, qrCodeDownload, copyLink,parsedTemplateSMS}"
                                    :social-main-banner-src="require('../assets/agent-main-banner.png')"
                                    :social-card-banner-src="require('../assets/agent-card-banner.png')"
                                    :qr-wrapper-src="require('../assets/qr-wrap.svg')">
                    <div class="card">
                        <div class="card-body">
                        <div class="row">
                        <div class="col-md-12 mb-4">
                            <span class="card-title">Zaproś klientów do korzystania z aplikacji bez wychodzenia z domu!</span>
                            <p class="fw-normal">Pozyskuj klientów w prosty i innowacyjny sposób z wykorzystaniem
                                unikalnych materiałów promocyjnych.</p>
                        </div>
                        <div class="col-md-4">
                            <span class="card-title">Skorzystaj z gotowego szablonu wiadomości SMS</span>
                            <p class="fw-normal">Skopiuj treść szablonu i wklej ją do bramki SMS lub wyślij na swój
                                numer telefonu.</p>

                        </div>
                        <div class="col-md-4">
                            <span class="card-title">Skorzystaj z gotowego szablonu wiadomości e-mail</span>
                            <p class="fw-normal">Skopiuj szablon wiadomości w HTML i wyślij go do klientów.</p>

                        </div>
                        <div class="col-md-4">
                            <span class="card-title">Promuj się w Social Mediach!</span>
                            <p class="fw-normal">Utwórz reklamę na Facebooku. Skopiuj link wraz z grafiką i użyj ich w
                                swojej reklamie.</p>

                        </div>

                        <div class="col-md-4 mb-3">
                            <div class="h-100 bg-grey rounded-4 p-4 text-center ">
                                <div class="phone-preview position-relative">
                                    <img src="@/assets/phone-wrap.svg" alt="" width="243" height="500"/>
                                    <div class="message">
                                        <div class="message-wrapper">
                                            <div class="message-date">środa, 9 lis 2021 : 9:41</div>
                                            <div class="message-popup">
                                                {{ parsedTemplateSMS }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4  mb-3">
                            <div class="h-100 bg-grey rounded-4 p-4 text-center">
                               <v-iframe :content="contentHtmlMail" :width="700" :height="1100" />
                            </div>
                        </div>
                        <div class="col-md-4  mb-3">
                            <div class="h-100 bg-grey rounded-4 p-4 text-center">
                                <div class="mb-4">
                                    <v-canvas width="600" height="600" style="width: 300px; height: 300px"
                                              :render="socialMainBanner" crossorigin="anonymous"/>
                                </div>
                                <div>
                                    <v-canvas width="600" height="314" style="width: 300px; height: 157px"
                                              :render="socialCardBanner" crossorigin="anonymous"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <v-control-textarea label="Treść wiadomośći SMS" v-model="model.templateSMS"/>
                            <div class="d-grid gap-2">
                                <v-button @click="copyTemplateSMS" label="Kopiuj treść SMS do schowka"  color="secondary" icon="download-line" :outline="true"/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <v-control-textarea label="Treść maila" v-model="model.templateMail"/>
                            <div class="d-grid gap-2">
                                <v-button @click="copyTemplateMail" label="Kopiuj kod HTML do schowka"  color="secondary" icon="download-line" :outline="true"/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <v-control-textarea label="Treść komunikatu Facebook" v-model="model.templateFb"/>
                            <div class="d-grid gap-2 mb-2">
                                <v-button label="Kopiuj treść komunikatu Facebook" @click="copyTemplateFB" color="secondary" icon="download-line" :outline="true"/>
                                <v-button label="Pobierz grafiki Facebook" crossorigin="anonymous" @click="socialBannerDownload" color="secondary" icon="download-line" :outline="true"/>
                            </div>
                            <div class="d-grid gap-2">
                                <v-button label="Kopiuj link" @click="copyLink" color="secondary" size="xs" :outline="true"/>
                            </div>
                        </div>
                        <div class="col-md-12 pt-5">
                            <div class="bg-darkblue rounded py-4 px-5 pattern6">
                                <span class="card-title text-white">Zaproś klientów do swojej aplikacji bez wychodzenia z domu!</span>
                                <p class="fw-normal text-white">Możesz także skopiować sam link i dowolnie go wykorzystać w celu zapraszania klientów do aplikacji.</p>
                                <v-button @click="copyLink" label="Kopiuj link" color="gold" icon="link" class="px-5 mt-4"/>
                            </div>
                        </div>


                    </div>
                        </div>
                    </div>


                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <span class="card-title">Twój Unikalny Kod QR</span>
                                    <p class="fw-normal">Pozyskuj klientów i promuj aplikację z wykorzystaniem Twojego unikalnego kodu QR.<br/> Udostępniaj kod QR gdziekolwiek chcesz i daj się poznać swoim przyszłym klientom!
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <v-canvas width="500" height="769" class="w-100" :render="qrCodeAd"/>
                                    </div>
                                    <div class="d-grid gap-2">
                                        <v-button @click="qrCodeAdToPdf" label="Pobierz plakietkę do druku" icon="download-line" size="lg" color="primary"></v-button>
                                        <p class="small text-dark text-center">Wymiary 95x135 mm, PDF</p>
                                        <v-button label="Zamów samoprzyleplne naklejki" icon="chat-check" size="lg" disabled color="primary"></v-button>
                                        <p class="small text-dark text-center">Dostępne już wkrótce</p>
                                    </div>

                                </div>
                                <div class="col-md-8">
                                    <img src="../assets/bg-loando-qr.png" class="w-100" alt=""/>
                                    <p class="text-center p-2 small text-dark">Wizualizacja skanowania kodu przez Twojego Klienta. Pomyśl tylko gdzie mógłby to zrobić?</p>
                                    <span class="card-title">Garść przydatnych informacji</span>
                                    <ul class="greater list-slimdot text-dark">
                                        <li>Rekomendujemy umieszczanie kodu w miejscach łatwo dostępnych, np. szyba biura, witryna lokalnego sklepu, kiosk, słup albo tablica reklamowa, dedykowany stojak na biurku zwrócony w stronę Klienta</li>
                                        <li>Twój kod QR powinien być umieszczony w dość jasno oświetlonym miejscu, tak aby móc zrobić wyraźne zdjęcie</li>
                                        <li>Kod QR można odczytać za pomocą aplikacji Aparatu bądź aplikacji o nazwie QR Code Scanner</li>
                                        <li>Po zeskanowaniu i zalogowaniu się do aplikacji, panel automatycznie doda Klienta do Twojej listy Klientów.</li>
                                        <li>Rekomendujemy zlecenie wydruków na papierze samoprzylepnym.</li>
                                        <li>
                                            Kod może nie działać w pewnych przypadkach:
                                            <ul class="list-slimdot">
                                                <li>jeśli jest zbyt mały na folii One Way Vision</li>
                                                <li>jeśli kolor tła pod kodem ulegnie zmianie</li>
                                            </ul>
                                        </li>

                                    </ul>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <div class="bg-light-subtle p-3 d-flex justify-content-between rounded align-items-center">
                                        <div>
                                            <span class="card-title fs-6">Zlecasz komuś reklamę i potrzebujesz samego kodu QR?</span>
                                            <p class="mb-0  text-dark">Jeśli potrzebujesz kodu QR do własnej autorskiej reklamy - kliknij w tym celu na przycisk obok, aby pobrać kod. Format 1500x1500 px.
                                            </p>
                                        </div>
                                        <div>
                                            <v-button label="Pobierz kod QR" @click="qrCodeDownload" color="secondary" size="xs" :outline="true" icon="download-line" class="px-4"/>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                </v-model.promotions>


    </section>
</template>
<script>
import VButton from "@/components/Button";
export default {
    name: "route-agent.promotions",
    components: {VButton},
    route: {
        path: 'materialy-promocyjne',
        title: 'Materiały promocyjne'
    }
}
</script>