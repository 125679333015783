<template>
    <div class="col-md-3">
        <div class="product mb-3 " :class="{active: offer.value, 'selected': offer.checked}">
            <div class="p-3 flex-grow-1">
                <div class="py-1">
                    <div class="product-logo-image d-flex justify-content-center align-items-center">
                        <img :alt=" offer.label" :src="offer.thumbnail" class="logo-image" style="width: 126px; height: 42px;"/>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <span v-if="offer.term === 'long'" class="text-end main-key">Miesięczna rata</span>
                    <span v-if="offer.term === 'short'" class="text-end main-key">Do spłaty</span>
                    <div class="col-md-12">
                      <span class="bigger-number">123 </span>
                      <span class="bigger-unit">zł</span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center align-items-center gap-5">
                    <div class="text-center offer-amount-tile">
                      <span class="text-end main-key">Kwota</span>
                      <span class="number">{{ parseFloat(offer.amount).toPrettyFormat() }}</span>
                      <span class="unit">{{ offer.amountlabel }}</span>
                    </div>
                    <div class="text-center offer-amount-tile">
                      <span class="text-end main-key">Okres</span>
                      <span class="number">{{ offer.period }}</span>
                      <span class="unit">{{ offer.periodlabel }}</span>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2 d-flex justify-content-between align-items-center">
                    <span class="rrso">RRSO</span>
                    <span class="rrso-value">99%</span>
                  </div>
                </div>
            </div>
            <div>
                <v-control-toggle :label="['Zaznacz ofertę', 'Zaznaczono']" :color="[null,'primary']" v-model="offer.checked" size="lg" />
            </div>

        </div>

    </div>
</template>
<script>
import VControlHidden from "@/components/Control/Hidden.vue";

export default {
    name: "v-wniosker-offer",
  components: {VControlHidden},
    props: {
        offer: {required: true}
    },
}
</script>
<style>
.main-key {
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #293042;
}
.selected {
  border-color: #293042;
  background: #FFFFFF;
  box-shadow: 0 0 0 5px rgba(40, 79, 134, 0.2);
}
</style>