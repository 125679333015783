<template>
    <div>
        <strong v-if="label">{{ label }}:&nbsp;</strong>
        <div class="date" :class="{['text-'+size]: !!size}"> {{ dateFormatted }}</div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "v-date",
    props: {
        date: String,
        size: String,
        showIcon: { type: Boolean, default: true},
        label: String,
        format: { type: String, default: 'HH:mm DD-MM-YYYY' }
    },
    computed: {
        dateFormatted() {
            // (new Date(this.parseVariable(this.date))->toLocaleString('pl-PL', {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric"});
            return moment(this.date).locale('pl_PL').format(this.format);
        }
    }
}
</script>

<style scoped>

</style>