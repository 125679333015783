<template>
    <td :class="$props['class']">
        <slot/>
    </td>
</template>

<script>
export default {
    name: "v-column",
    props: {
        label: { type: String },
        class: { type: String}
    }
}
</script>
