<template>
    <v-collection :items="products">
        <template v-slot:default="{ item }">
            <template v-if="item.mode === 'group'">
                <v-wniosker-group v-if="item.elements?.find((elm) => elm.mode !== 'group')" :group="item"/>
                <v-wniosker-products v-else :products="item.elements"></v-wniosker-products>
            </template>
            <template v-else>
                <v-wniosker-product :product="item"/>
            </template>

        </template>

        <template v-slot:empty>
            <div>
                tu pojawią się oferty dla klienta
            </div>
        </template>
        <template v-slot:loading>
            <v-loading text="Trwa wczytywanie..."></v-loading>
        </template>

    </v-collection>
</template>

<script>
import VWnioskerGroup from "@/shared/wniosker/group";
import VWnioskerProduct from "@/shared/wniosker/product";
export default {
    name: "v-wniosker-products",
    components: {VWnioskerProduct, VWnioskerGroup},
    props: {
        products: { type: Array, required: true }
    }
}
</script>

<style scoped>

</style>