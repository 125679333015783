<template>
    <div class="agent-info">
        <span class="d-block name">{{ agent.firstName ? [agent.firstName, agent.lastName].join(' ') : agent.username }}</span>
        <span class="d-block">{{ roleName }}</span>
        <span class="d-block sub">{{ agent.phone}}</span>
        <span class="d-block sub">{{ agent.email }}</span>
    </div>
</template>

<script>
export default {
    name: "v-agent",
    props: {
        agent: {}
    },
    computed: {
        roleName() {

            if(this.agent.role === 'manager')
                return 'Agent';

            if(this.agent.role === 'agent')
                return 'Ekspert';

            return this.agent.role;
        }
    }
}
</script>

<style scoped>

</style>