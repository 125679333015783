import model from "@/mixins/model";

export default {
    mixins: [model],
    model() {
        return {
            lead: {}
        }
    },
    mounted() {
        this.fetch();
    },
    data() {
        return {
            loadingWebAgreements: false,
            loadingAgreements: false,
            isSentAgreements: false
        }
    },
    methods: {
        sentAgreements() {
            this.loadingAgreements = true;

            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'leads/:lead/agreements-link', method: 'PATCH' })
                    .then(() => this.isSentAgreements = true)
                    .then(resolve)
                    .catch(reject)
                    .then(() => this.loadingAgreements = false);

            });
        },
        sentWebAgreements() {
            this.loadingWebAgreements = true;

            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'leads/:lead/agreements-www-link', method: 'PATCH' })
                    .then(() => this.isSentAgreements = true)
                    .then(resolve)
                    .catch(reject)
                    .then(() => this.loadingWebAgreements = false);

            });
        },
        fetch() {

            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'leads/:lead/events' })
                    .then((response) => {

                        this.model.lead = response.data.lead;
                    })
                    .then(resolve)
                    .catch(reject);

            });


        },
    }
}