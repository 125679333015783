<template>
    <section>
        <div class="d-flex justify-content-between">
            <div class="hgroup">
                <h1>Importuj klientów</h1>
                <h2>Zaimportuj plik z danymi klienta</h2>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "v-agent.import",
    route: {
        path: 'import',
        title: 'Importuj klientów'
    }
}
</script>