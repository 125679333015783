<template>
    <div class="col-md-9">
        <div class="d-flex justify-content-center align-items-center h-100  border rounded">
            <div class="text-center">
                <p class="fs-2 fw-600 lh-sm">Rozpocznij nowy czat albo<br> wybierz z istniejących</p>
                <p>Możesz skorzystać z wyszukiwania <br/> po PESEL albo Nazwisku</p>
            </div>
        </div>

    </div>
</template>

<script>

import VButton from "@/components/Button";
export default {
    name: "route-agent.chats",
    components: {VButton},
    route: {
        path: 'index'
    }
}
</script>