<template>
    <div :class="{'mb-3':!slim}">
        <label class="form-label">{{ label }}</label>
        <div class="input-group">
            <input :type="type" @blur="showPassword = false" class="form-control" :placeholder="placeholder" v-model="value" :class="{filled: !!value}">
            <span class="btn-control" @click="showPassword = !showPassword" :class="{active: showPassword && !!value}">
                <v-icon :icon="showPassword ?  'eye' : 'eye-blocked'" />
            </span>
        </div>
        <p class="form-control-error text-danger" :key="msg" v-for="msg in error">błąd: {{ msg }}</p>
    </div>
</template>

<script>
import control from "@/mixins/control";

export default {
    name: "v-control-input",
    mixins: [control],

    data() {
        return {
            showPassword: false
        }
    },
    computed: {
        type() {
            return this.showPassword ? 'text' : 'password';
        }
    }
}
</script>

<style scoped>

</style>