<template>
    <section>
        <v-model.posts v-slot="{ model, select, loading, isActive }">
            <div class="d-flex justify-content-between">
                <div class="hgroup">
                    <h1>Najświeższe informacje z naszej sieci</h1>
                    <h2>To Twoje centrum dowodzenia</h2>
                </div>
            </div>
            <v-model.posts.category v-slot="{model}">
                <div class="news news-category mb-3" v-if="model.categories.length">
                    <v-news-category title="Wszystkie komunikaty" color="white" @click="select(null)" name="category" :active="isActive(null)"/>
                    <v-news-category :key="category.slug"
                                     v-for="category in model.categories"
                                     :title="category.name"
                                     :color="category.css_class"
                                     :slug="category.slug"
                                     @click="select(category.slug)"
                                     :active="isActive(category.slug)"
                                     name="category"/>
                </div>
            </v-model.posts.category>

            <div >
                <div v-if="loading" class="p-3">
                    <v-loading />
                </div>
                <div v-else-if="model.posts.length" class="news">
                    <v-news-post v-for="post in model.posts"
                                 :key="post.id"
                                 :title="post.title"
                                 :content="post.content"
                                 :date="post.published"
                                 :color="post.category[0].css_class"
                                 :category="post.category[0].name"/>
                </div>
                <div v-else class="text-center fs-4 p-3">
                    <p>Brak aktualności</p>
                </div>

            </div>

        </v-model.posts>


    </section>
</template>

<script>
import VNewsCategory from "@/shared/news/category";
import VNewsPost from "@/shared/news/post";
import VLoading from "@/components/Loading";
export default {
    name: "route-agent.posts",
    components: {VLoading},
    route: {
        path: '/aktualnosci',
        title: 'Aktualności'
    }
}
</script>

<style scoped>

</style>