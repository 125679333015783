import {ref} from "vue";

class WsConnection {
    constructor(url, params) {

        this.ws = new WebSocket([url, new URLSearchParams(params.query ?? [])].join('?'));
        this.isConnected = ref(false);
        this.retry = params.attempt > 1
        this.retryAttempt = params.attempt ?? 0
    }

    send(signature, data) {
        this.ws.send(JSON.stringify({signature, ...data}));
    }

    open(callback) {

        this.ws.onopen = () => {

            this.isConnected.value = true
            callback();
        }

        return this;
    }

    message(callback) {
        this.ws.onmessage = (evt) => {
            callback(JSON.parse(evt.data));
        }

        return this;
    }

    error(callback) {
        this.ws.onerror = (e) => {
            callback(e)
        }

        return this;
    }

    close(callback) {

        this.ws.onclose = () => {
            callback();

            this.isConnected.value = false
        }


        return this;
    }


}

export default {
    install(app, options) {

        app.config.globalProperties.$ws = {
            connect(params) {

                const url = [options[params.name ?? 'default']?.url?.trimChar('/'),params.url.trimChar('/')].join('/')

                return new WsConnection(url, params);
            }
        }

    }
}