<template>
   <section>
       <v-wniosker-form />
   </section>
</template>

<script>
export default {
    name: "v-route-agent.apply.formularz",
    view: 'layer',
    route: {
        path: 'formularz:uri(.*)',
        title: 'Formularz'
    }
}
</script>

