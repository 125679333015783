import model from '../mixins/model';

export default {
    mixins: [model],
    form: true,
    model() {
        return {
            action: null,
            lead: null,
            appform: null,
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        submit(e) {
            e.preventDefault();

            const data = {agreement_4801: 1}

            return this.dispatch((resolve,reject) => {
                this.$http.request({ url: 'leads/:lead/finish', routeParams: {lead: this.model.lead.uid}, params: {appform: this.model.appform}, method: 'PATCH', data})
                    .then((response) => {

                        // console.log(response);

                        let redirect = response.data?.redirect;
                        if (redirect) {
                            this.$router.push(this.normalizePath({path:response.data.redirect})).then(resolve);
                        }
                    })
                    .catch(reject)
            });
        }
    }
}