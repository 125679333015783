<template>
    <section>
        <v-model.lead v-slot="main">
            <div class="d-flex justify-content-between">

                <div>
                    <v-button class="px-0 py-2" color="transparent" icon="arrow-left-s"
                              @click="$router.go(-1)"/>
                </div>
                <div class="hgroup flex-grow-1">
                    <h1>Szczegóły wniosku nr: {{ main.model.lead.hashid }}</h1>
                    <h2>Klient: {{ main.model.lead.client?.firstName }} {{ main.model.lead.client?.lastName }}</h2>
                </div>
            </div>
            <div v-if="main.model.lead" class="card clients-data">
                <div class="card-body">

                    <span class="card-title">Dane klienta</span>

                    <div class="d-flex justify-content-between label-text align-items-end">
                        <p>
                            <span class="label">Imię</span>
                            <span class="text">{{ main.model.lead.firstName || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Nazwisko</span>
                            <span class="text">{{ main.model.lead.lastName || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Telefon</span>
                            <span class="text">{{ main.model.lead.phone || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Pesel</span>
                            <span class="text">{{ main.model.lead.personId || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Email</span>
                            <span class="text">{{ main.model.lead.email || '-' }}</span>
                        </p>
                        <p v-if="$auth.permissions.mobileEnabled">
                            <span class="label">Aplikacja</span>


                        <span class="d-flex align-items-center" :class="{'text-green': main.model.lead?.client?.hasMobileApp, 'text-red': !main.model.lead?.client?.hasMobileApp}">
                            <v-icon icon="smartphone"></v-icon>
                            <span class="fw-700 text-small text-uppercase mt-1 mx-1">{{ main.model.lead?.client?.hasMobileApp ? 'Aktywna' : 'Brak' }}</span>
                        </span>


                        </p>
                        <div class="d-grid gap-2 w-200">
                            <v-button :outline="true" color="secondary" icon="user" label="Szczegóły klienta"
                                      @click="advanced =  !advanced"/>

                        </div>
                    </div>
                </div>
            </div>
            <div v-if="advanced" class="card">
                <div class="card-body">
                    <div v-if="main.model.agent?.firstName" class="mb-4">
                        <div class="mb-2">
                            <span class="card-title mb-0">Agent</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <img :alt="main.model.agent.firstName+' '+main.model.agent.lastName" :src="main.model.agent.avatar"
                                 class="rounded avatar ms-0"/>
                            <div>
                                <v-agent :agent="main.model.agent"/>

                            </div>
                        </div>
                    </div>
                    <v-model.lead.data v-slot="{ model }">
                        <v-collection :items="model.lead">
                            <template v-slot:default="{ item }">
                                <span class="card-title">{{ item.group_name }}</span>
                                <div class="striped mt-3 mb-4">
                                    <v-collection v-slot="{item}" :items="item.fields">
                                        <div class="striped-item">
                                            <div class="row">
                                                <div class="col-md p-3">
                                                    <strong>{{ item.label }}</strong>
                                                </div>
                                                <div class="col-md p-3">
                                                    <span>{{ item.value }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </v-collection>
                                </div>

                            </template>
                            <template v-slot:loading>
                                <div class="py-3">
                                    <v-loading color="secondary" text="Trwa wczytywanie"/>
                                </div>
                            </template>
                            <template v-slot:empty>
                                <span>Brak danych klienta</span>
                            </template>
                        </v-collection>
                    </v-model.lead.data>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <v-model.lead.events ref="events"
                                         v-slot="{model,sentAgreements,sentWebAgreements,loadingAgreements,loadingWebAgreements,isSentAgreements}">
                        <div v-if="isSentAgreements" class="alert alert-success vert-middle" role="alert">
                            <v-icon icon="check-line fs-4"/>
                            Zgody zostały wysłane do klienta
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="clients me-2" v-if="main.model.lead.withMobileApp">
                                    <span class="text-green fs-4">
                                      <v-icon icon="smartphone"></v-icon>
                                        </span>
                                </div>

                                <small class="mb-0 fw-600">
                                    Status wniosku
                                </small>
                                <div class="applications d-flex align-items-center mx-5">
                                    <v-collection v-slot="{item}" :items="model.lead.event_statuses">
                                        <v-icon :class="{['text-'+item.color]: !!item.color}" :icon="item.icon"
                                                :title="item.hint"
                                                class="fs-4 me-1"/>
                                    </v-collection>
                                </div>
                                <div v-if="model.lead.lead_status">
                                    <div :class="{['bg-'+model.lead.lead_status.color]: true}"
                                         class="lead-status mt-0 px-5">
                                        {{ model.lead.lead_status.hint }}
                                    </div>
                                </div>
                                <div class="px-2 d-flex">
                                    <v-button :disabled="!!model.lead.sendAgreementsBtn" :loading="loadingAgreements"
                                              :outline="true" color="secondary"
                                              icon="send"
                                              label="Wyślij zgody do klienta" size="xs"
                                              @click="sentAgreements"/>

                                    <v-button v-if="$auth.permissions.mobileEnabled" :disabled="!!model.lead.sendAgreementsBtn" :loading="loadingWebAgreements"
                                              :outline="true" color="secondary"
                                              icon="send"
                                              class="ms-2"
                                              label="Wyślij link tradycyjny (zgody)" size="xs"
                                              @click="sentWebAgreements"/>
                                </div>


                            </div>
                            <div class="w-200 d-grid gap-2">
                                <v-button :outline="true" color="secondary" icon="refresh"
                                          label="Odśwież statusy"
                                          size="xs"
                                          @click="refreshAll"/>
                            </div>
                        </div>
                    </v-model.lead.events>

                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <v-model.leads.offers ref="offers" v-slot="{model,fetch,checked,isSentOffer,loading,submitWeb,loadingApp,loadingWeb,test}">
                        <div v-if="isSentOffer" class="alert alert-success vert-middle" role="alert">
                            <v-icon icon="check-line fs-4"/>
                            Oferty zostały wysłane do klienta
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <div>
                                <span class="card-title">Dostepne oferty</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <div v-if="model.loadAt" class="me-3">Stan ofert: {{ model.loadAt }}</div>
                                <div class="d-grid gap-2 w-200">
                                    <v-button :outline="true" color="secondary" icon="refresh" label="Sprawdź dostępnosć"
                                              size="xs"
                                              @click="fetch"/>
                                </div>

                            </div>
                        </div>
                        <div>
                            <v-wniosker-offers v-if="!loading" :offer="{elements:model.lead.offers}"  @refresh="fetch"/>
                            <v-loading v-else />
                        </div>
                        <div class="d-flex justify-content-end align-items-center">
                            <div v-if="!!checked" class="d-flex  align-items-center">
                                <div class="text-end lh-normal">
                                    Zaznaczonych <br> ofert
                                </div>
                                <div class="fs-1 fw-600 px-2 me-2 lh-normal">
                                    {{ checked }}
                                </div>
                            </div>
                            <div class="d-grid gap-2 w-200">
                                <v-button :disabled="!checked" :loading="loadingApp" color="gold" icon="apps" label="Wyślij do klienta"
                                          submit/>

                            </div><div v-if="$auth.permissions.mobileEnabled" class="d-grid gap-2">
                                <v-button :disabled="!checked" color="secondary" :loading="loadingWeb" :outline="true" icon="link" label="Wyślij link tradycyjny (oferty)" class="ms-2"
                                          @click="submitWeb"/>

                            </div>
                        </div>
                    </v-model.leads.offers>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <v-model.lead.actions ref="actions" v-slot="{model,toggle}">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="me-4">
                                <small class="mb-0 fw-600">
                                    Status rozmowy
                                </small>
                            </div>
                            <div class="d-flex flex-grow-1 actions">
                                <v-collection v-slot="{ item }" :items="model.lead.action_statuses">
                                    <v-button :color="item.color" :icon="item.icon" :label="item.label" size="md" :remove-border="true"
                                              :loading="item.loading" :title="item.hint" class="flex-grow-1 d-flex flex-row ms-2 align-items-center pt-2 pb-2 gap-1"
                                              style="font-size:18px"
                                              @click="toggle(item)"/>
                                </v-collection>
                            </div>
                        </div>
                    </v-model.lead.actions>

                </div>
            </div>


            <v-model.leads.applications ref="applications" v-slot="{model,pagination}">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">
                            Statusy ofert: {{ main.model.lead.hashid }}
                            <v-button @click="collapseApplicationsList" color="secondary" :outline="true" :icon="this.isApplicationsListCollapsed ? 'arrow-down-s' : 'arrow-up-s'" :label="this.isApplicationsListCollapsed ? 'Rozwiń' : 'Zwiń'" size="xs" class="ms-2 ps-5 pe-5 float-end"/>
                        </div>
                        <v-table v-if="!this.isApplicationsListCollapsed" v-slot="{item}" :items="model.applications" class="applications">
                            <v-column label="Firma">
                                <img :src="item.product.thumbnail" alt="brak logotypu" class="logo-image-min"/>
                            </v-column>
                            <v-column label="Produkt">
                                <span>{{ item.product.label }}</span>
                            </v-column>
                            <v-column label="Ekspert">
                                <div class="d-flex flex-column text-start">
                                    <span>{{ item.agent.firstName }}</span>
                                    <span>{{ item.agent.lastName }}</span>
                                </div>
                            </v-column>
                            <v-column label="Data złożenia">
                                <div class="d-inline-block text-start">
                                    <v-date :date="item.created_at" class="d-block" format="YYYY/MM/DD"/>
                                    <v-date :date="item.created_at" class="d-block" format="HH:mm"/>
                                </div>
                            </v-column>
                            <v-column class="text-end" label="Kwota">
                                <span class="number">{{ item.amount }}</span>
                                <span class="d-block unit">{{ item.product.amount_label }}</span>
                            </v-column>
                            <v-column class="text-end" label="Okres">
                                <span class="number">{{ item.period }}</span>
                                <span class="d-block unit">{{ item.product.period_label }}</span>
                            </v-column>
                            <v-column class="text-center " label="Status oferty">
                                <div class="d-flex justify-content-center">
                                    <div :class="{['bg-'+item.status.color]: true}" class="lead-status"
                                         style="width: 140px">
                                        {{ item.status.label }}
                                    </div>
                                </div>
                            </v-column>
                            <v-column label="#ID" style="width: 100px">
                                <span>#{{ item.hashid }}</span>
                            </v-column>
                        </v-table>

                        <v-pagination v-if="!this.isApplicationsListCollapsed" :pagination="pagination"/>
                    </div>
                </div>
            </v-model.leads.applications>


        </v-model.lead>
    </section>
</template>

<script>

import VLoading from "@/components/Loading";
export default {
    name: "route-agents.leads.preview",
    components: {VLoading},
    view: "layer",
    route: {
        path: "szczegoly/:lead",
        title: "Szczegoly wniosku"
    },
    data() {
        return {
            advanced: false,
            isApplicationsListCollapsed: false
        }
    },
    methods: {
        refreshAll() {

            this.$refs.actions.fetch();
            this.$refs.offers.fetch();
            this.$refs.applications.fetch();
            this.$refs.events.fetch();
        },
        collapseApplicationsList() {
          this.isApplicationsListCollapsed = !this.isApplicationsListCollapsed;
        }
    }
}
</script>

<style scoped>

</style>