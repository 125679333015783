<template>
    <div class="col-md-3">
        <div :class="{'product': true, 'mb-3': true, 'selected': product.checked}">
          <div class="product-description p-3 flex-grow-1" @click="this.offerMixin.setOfferAsChecked(product)">
                <div class="py-1">
                    <div class="product-logo-image d-flex justify-content-center align-items-center">
                        <img :alt="product.label" :src="product.thumbnail" class="logo-image" style="width: 126px; height: 42px;"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mb-2">
                        <span class="text-end main-key">Kwota</span>
                        <span class="number">{{ getAmountRange }}</span>
                        <span class="unit">{{ product.amount_label }}</span>
                    </div>
                    <div class="col-md-12 text-center">
                        <span class="text-end main-key">Okres</span>
                        <span class="number">{{ getPeriodRange }}</span>
                        <span class="unit">{{ product.period_label }}</span>
                    </div>
                </div>
            </div>


            <div>
                <v-control-toggle v-model="product.checked" :color="[null,'primary']"
                                  :label="['Zaznacz ofertę', 'Zaznaczono']" size="lg"/>
            </div>
        </div>

    </div>
</template>

<script>
import offerMixin from "@/mixins/offer";

export default {
    name: "v-wniosker-product",
    props: {
        product: {required: true},
    },
    mixins: [offerMixin],
    data() {
      return {
        offerMixin: offerMixin
      }
    },
    computed: {
      getAmountRange() {
        if (this.product.max_amount === this.product.min_amount) {
          return parseFloat(this.product.max_amount || 0).toPrettyFormat();
        }

        return parseFloat(this.product.min_amount || 0).toPrettyFormat() + ' - ' + parseFloat(this.product.max_amount || 0).toPrettyFormat();
      },
      getPeriodRange() {
        if (this.product.max_period === this.product.min_period) {
          return parseFloat(this.product.max_period || 0).toPrettyFormat();
        }

        return parseFloat(this.product.min_period || 0).toPrettyFormat() + ' - ' + parseFloat(this.product.max_period || 0).toPrettyFormat();
      }
    }
}
</script>
<style>
.selected {
  border-color: #293042;
  background: #FFFFFF;
  box-shadow: 0 0 0 5px rgba(40, 79, 134, 0.2);
}
</style>