import model from "@/mixins/model";
import moment from "moment";

export default {
    mixins: [model],
    form: true,
    model() {
        return {
            lead: {},
            loadAt: null
        }
    },
    data() {
        return {
            loadingApp:false,
            loadingWeb:false,
            isSentOffer: false
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        submit(e) {
            e.preventDefault();

            return this.dispatch((resolve, reject) => {

                this.loadingApp  = true;

                this.$http.request({ url: 'leads/:lead/offers', method: 'PATCH', data: {lead:{offers:this.formOffers}} })
                    .then(() => {
                        this.isSentOffer = true;
                    })
                    .then(resolve)
                    .then(() => this.fetch())
                    .catch(reject)
                    .then(() => this.loadingApp = false);
            });
        },

        submitWeb(e) {
            e.preventDefault();

            return this.dispatch((resolve, reject) => {

                this.loadingWeb = true;

                this.$http.request({ url: 'leads/:lead/offers-www', method: 'PATCH', data: {lead:{offers:this.formOffers}} })
                    .then(() => {
                        this.isSentOffer = true;
                    })
                    .then(resolve)
                    .then(() => this.fetch())
                    .catch(reject)
                    .then(() => this.loadingWeb = false);
            });
        },
        fetch() {


            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'leads/:lead/offers' })
                    .then((response) => {

                        this.model.lead = response.data.lead;
                        this.model.loadAt = moment().format('DD.MM.YYYY, HH:mm');
                    })
                    .then(resolve)
                    .catch(reject);

            });


        },
    },
    computed: {
        formOffers() {
            if(!this.model.lead.offers) return [];

            return this.model.lead.offers.filterRecursive(item => item, "elements").map(({id,checked}) => ({id,checked}));
        },
        checked() {
            if(!this.model.lead.offers) return 0;

            return this.model.lead.offers.filterRecursive(item => item?.checked, "elements").length;
        }
    }
}