<template>
    <Teleport v-if="added" to="#app">
        <div class="modal fade d-flex align-items-center image-preview" :class="{show}"  tabindex="-1">
            <div class="modal-dialog flex-grow-1">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                    </div>
                    <div class="modal-body">
                        <img :src="url" alt="#" class="w-100 rounded"  />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade" :class="{show}"/>
    </Teleport>

    <slot v-bind="{open}" />
</template>

<script>
export default {
    name: "PreviewImage",
    props: {
        url: {type: String}
    },
    data() {
        return {
            added: false,
            show: false,
        }
    },
    methods: {
        open() {
            this.added = true;
            setTimeout(() => this.show = true, 100);
        },
        close() {
            this.show = false;
            setTimeout(() => this.added = false, 400);
        }
    }

}
</script>

<style scoped>

</style>