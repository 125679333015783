import model from '../mixins/model';

export default {
    mixins: [model],
    query() {
        return {
            month: null
        }
    },
    model() {
        return {
            applications: {},
            products: {},
            offers: {},
            clients: {},
            charts: {},
            soldProducts: [],
            userData: {},
            currentMonth: {
                val: null
            },
            previousMonth: {
                val: null
            }
        }
    },
    watch: {
        '$globalQueries.params.agent_id': {
            handler() {

                this.fetch()
            },
            deep: true
        },
        query: {
            handler() {

                this.fetch()
            },
            deep: true
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'dashboard', params: {...this.query, user_id: this.$globalQueries.params.agent_id } })
                    .then((response) => {
                        Object.assign(this.model, response.data);
                        this.query.month = this.model.currentMonth.val;
                        this.model.previousMonth.val = new Date(this.model.currentMonth.val).subMonths(1);
                    })
                    .then(resolve)
                    .catch(reject);

            });


        },
    }
}