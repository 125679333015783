<template>
    <section>
        <v-model.documents v-slot="{model}">
            <div class="d-flex justify-content-between">
                <div class="hgroup">
                    <h1>Dokumenty</h1>
                </div>
            </div>
            <div class="card" v-for="category in model.categories" :key="category.slug">
                <div class="card-body">
                    <span class="card-title">{{ category.name }}</span>

                    <div class="documents d-flex pt-2">
                        <a :href="document.document" target="_blank" v-for="document in category.documents" :key="document.id" class="document-item">
                            <span class="badge bg-secondary fs-4"><v-icon icon="file-pdf-line"/></span>
                            <span class="text">{{ document.title }}</span>
                        </a>
                    </div>


                </div>
            </div>
        </v-model.documents>

    </section>
</template>

<script>
export default {
    name: "route-agent.documents",
    route: {
        path: '/dokumenty',
        title: 'Dokumenty'
    }
}
</script>
