<template>
    <span>Trwa wylogowywanie</span>
</template>

<script>
export default {
    name: "route-logout",
    route: {
        path: '/logout',
        before() {

            console.log('wylogowuje');

            this.$auth.logout();
            this.$router.push({ name: "login" }).then(e => {
                console.log(e);
            }).catch(e => {
                console.warn(e);
            })
        }
    }
}
</script>