import {h, onMounted, ref} from "vue";

export default {
    props: {
        width: {},
        height: {},
        render: {}
    },
    setup({width,height,render}, {attrs}) {
        const el = ref(null)

        onMounted(() => {

            const ctx = el.value.getContext('2d');

            if(!render) return;

                // render(ctx,el.value,{width,height});


                    render(ctx,el.value,{width,height});



        })


        return () => h('canvas', {ref:el,width,height,...attrs});
    }
}