import model from "@/mixins/model";
import moment from "moment";

export default {
    mixins: [model],
    inject: {
        chat: {from: 'chat'},
        chatRecipients: {from: 'chatRecipients'},
    },
    model() {
        return {
            client: {},
            messagesEntries: {},
            searchText: null
        }
    },
    provide() {
        return {
            chatConversation: this
        }
    },
    data() {
        return {
            focused: false,
            searchTimeout: null,
            searchMode: false,
            foundTextCount: null,
            foundTextShowIndex: null
        }
    },
    watch: {
        'foundTextShowIndex': {
            handler() {

                this.pointerMessage();
            }
        },
        'focused': {
            handler() {
                this.seenMessages()
            }
        },
        'model.searchText': {
            handler(value) {
                clearInterval(this.searchTimeout)

                this.searchTimeout = setTimeout(() => {

                    this.searchInMessages();
                    this.pointerMessage();

                }, 500);
            }
        },
        '$route.params': {
            handler(params) {
                if (!params.client) return;

                this.fetch()
                this.clearSearchInMessages();

            },
            deep: true
        },
        'messages': {
            handler() {
                setTimeout(() => {
                    if (document.querySelector('#chat-scrollview') && !this.searchMode)
                        document.querySelector('#chat-scrollview').scrollTop = document.querySelector('#chat-scrollview').scrollHeight
                }, 100)
                //
            }
        }
    },
    beforeUnmount() {
        this.chat.off('message.push', this.addMessage)
        this.chat.off('message.all', this.allMessage)
    },
    mounted() {
        this.fetch()

        this.chat.on('message.push', this.addMessage)
        this.chat.on('message.all', this.allMessage)

    },
    computed: {
        messages() {
            return Object.values(this.model.messagesEntries).sort((a, b) => a.message.date - b.message.date).reduce((items, b) => {

                let lastGroup = items[items.length - 1];

                let group;

                if (lastGroup && lastGroup.authorId === b.group.authorId && (b.group.date - lastGroup.date) < 3 * 60 * 1000) {

                    group = lastGroup;
                } else {
                    group = {
                        ...b.group,
                        messages: []
                    };
                    items.push(group);
                }

                group.messages.push(b.message);

                return items;
            }, []);
        }
    },
    methods: {
        pointerMessage() {
            setTimeout(() => {
                if (this.searchMode) {
                    document.querySelector('#chat-scrollview').querySelectorAll('.search-highlight').item(this.foundTextShowIndex - 1)?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest"
                    });
                }
            }, 100)
        },
        nextFoundText() {
            this.foundTextShowIndex = this.foundTextShowIndex < this.foundTextCount ? this.foundTextShowIndex + 1 : this.foundTextShowIndex;
        },
        prevFoundText() {
            this.foundTextShowIndex = this.foundTextShowIndex > 1 ? this.foundTextShowIndex - 1 : this.foundTextShowIndex;
        },
        clearSearchInMessages() {
            this.searchMode = false;
            this.foundTextCount = null;
            this.foundTextShowIndex = null;
            this.model.searchText = null;

            Object.values(this.model.messagesEntries).map((item) => {

                item.message.foundSearchText.count = null
                item.message.foundSearchText.regex =  null;
                item.message.foundSearchText.index = null;

                return item;
            })
        },
        searchInMessages() {

            this.searchMode = this.model.searchText?.length >= 3;

            const regex = new RegExp(`(${this.model.searchText})`, 'ig');

            let index = 0;

            this.foundTextCount = Object.values(this.model.messagesEntries).map((item) => {

                item.message.foundSearchText.count = this.searchMode ? (item.message.text.match(regex) || []).length : 0
                item.message.foundSearchText.regex = item.message.foundSearchText.count ? regex : null;
                item.message.foundSearchText.index = index;
                index += item.message.foundSearchText.count;

                return item;
            }).reduce((a, b) => {
                return a + b.message.foundSearchText.count;
            }, 0);

            this.foundTextShowIndex = 1;


        },
        allMessage(payload) {

            payload.messages.forEach((message) => {
                this.pushMessage(message);
            });

            this.seenMessages();
        },
        addMessage(message) {
            this.pushMessage(message);
            this.seenMessages();
        },

        focusConversation() {
            this.focused = true
        },
        seenMessages() {

            if (!this.focused || this.searchMode) return;

            const unseenMessagesId = Object.values(this.model.messagesEntries).filter(item => !item.message.viewAt).map(item => item.message.id);

            if (unseenMessagesId.length) this.chat.send('message.seen', {
                recipientId: this.$route.params.client,
                messageId: unseenMessagesId
            });

        },
        pushMessage(message) {

            if (this.$auth.user.uid === message.authorId || this.model.client.uid === message.authorId)

                this.model.messagesEntries[message.id] = {
                    group: {
                        dateFormat: moment(message.sentAt).locale("pl").calendar(),
                        date: new Date(message.sentAt),
                        me: this.$auth.user.uid === message.authorId,
                        authorId: message.authorId,
                        author: this.$auth.user.uid === message.authorId ? 'Ja' : (this.model.client.uid === message.authorId ? this.model.client.firstName + ' ' + this.model.client.lastName : '[Nieznany]'),
                    },
                    message: {
                        id: message.id,
                        text: message.text,
                        viewAt: message.viewAt ? new Date(message.viewAt) : null,
                        images: message?.images?.map(({
                                                          token,
                                                          filename
                                                      }) => (this.$http.url('leado', `attachments/${token}/${filename}`))),
                        foundSearchText: {
                            index: null,
                            count: null,
                            regex: null
                        },
                        dateFormat: moment(message.sentAt).locale("pl").calendar(),
                        date: new Date(message.sentAt)
                    }

                }
        },
        submit(e) {
            e.preventDefault();

            this.chat.send('message.post', {text: this.model.text, recipientId: this.$route.params.client});
            this.chatRecipients.clearSearch();
            this.clearSearchInMessages();

            this.model.text = null;
        },
        fetch() {
            return this.dispatch((resolve, reject) => {
                this.model.messagesEntries = {};
                this.focused = false;

                this.$http.request({url: 'agent/chat-contacts/:client', params: {...this.query}})
                    .then((response) => {

                        this.model.client = {uid: this.$route.params.client, ...response.data.client};
                        this.chat.send('message.fetch', {recipientId: this.$route.params.client});
                    })
                    .then(resolve)
                    .catch(reject);

            });
        }
    }
}