<template>
    <div class="d-flex align-items-center indicator" :class="{['indicator-label']: !!date, increase: increase, decrease: !increase}">
        <div class="">
            <span class="badge">{{ increase ? '+' : '' }}{{value}}<span class="unit">{{unit}}</span></span>
        </div>
        <div v-if="date" class="ms-2 indicator-text">
            <span class="d-block fw-400">{{  increase ? 'wzrost względem' : 'spadek względem' }}</span>
            <span class="d-block fw-600">{{ monthFormatted }}</span>
        </div>
    </div>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "VIndicator",
    props: {
        number: {},
        unit: { type: String},
        date: {}
    },
    computed: {
        monthFormatted() {
            return moment(new Date(this.date)).locale('pl_PL').format('D MMMM YYYY').replace('1 ', '');
        },
        value() {
            return parseFloat(this.number);
        },
        increase() {
            return this.value > 0;
        }
    }
}
</script>

<style scoped>

</style>