import model from '../mixins/model';

export default {
    mixins: [model],
    form: true,
    model() {
        return {
            products: [],
            agreements: [],
            appform: null
        }
    },
    mounted() {
        this.fetch();
    },
    computed: {
        checkedProducts() {
            return this.model.products.filterRecursive(item => item.checked, "elements").map(item => item.name);
        } ,
        checkedAgreements() {
            return this.model.agreements.filter(item => item.checked).map(item => ({[item.name]: item.checked })).reduce((a,b) => Object.assign(a,b));
        }
    },
    methods: {
        submit(e) {
            e.preventDefault();

            const data = {...this.checkedAgreements}

            if(this.checkedProducts.length > 0)
                Object.assign(data, {products: this.checkedProducts});

            return this.dispatch((resolve,reject) => {
                this.$http.request({ url: 'lead/:appform', routeParams: {appform: this.model.appform}, params: { client: this.$route.params.client }, method: 'POST', data})
                    .then((response) => {

                        console.log(response);

                        this.$router.push(this.normalizePath({path:response.data.redirect})).then(resolve);
                    })
                    .catch(reject)
            });
        },
        fetch() {
            this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'products'})
                    .then((response) => {
                        this.model.products = response.data.data;
                        this.model.appform = response.data.appForm.hashid;
                        this.model.agreements = response.data.appForm.agreements;
                    })
                    .then(resolve)
                    .catch(reject);

            });
        }
    }
}