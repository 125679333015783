import model from "@/mixins/model";

export default {
    mixins: [model],
    model() {
        return {
            lead: {}
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        toggle(item) {
            item.loading = true;

            return this.dispatch((resolve, reject) => {

                this.$http.request({ method: 'PATCH', url: 'leads/:lead/index', data: {name: item.name,value: !item.value} })
                    .then(resolve)
                    .then(() => this.fetch())
                    .then(() => item.loading = false)
                    .catch(reject);
            });
        },
        fetch() {

            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'leads/:lead/actions' })
                    .then((response) => {

                        this.model.lead = response.data.lead;
                    })
                    .then(resolve)
                    .catch(reject);

            });


        },
    }
}