import model from "@/mixins/model";

export default {
    mixins: [model],
    data() {
        return {
            searchTimeout: null,
            searched: false,

        }
    },
    provide() {
        return {
            chatRecipients: this
        }
    },
    inject: {
        chat: {from: 'chat'}
    },
    watch: {

        'model.contactSearch': {
            handler(value, oldValue) {
                clearInterval(this.searchTimeout)

                this.searchTimeout = setTimeout(() => {

                   if(value && value.length > 2)
                       this.fetch();

                }, 500);

                if(this.model.contactSearch?.length < 3 && oldValue?.length > 2)
                    this.fetch();
            }
        },
        'model.searchMode': {
            handler() {

                console.log('zmiana', this.model.searchMode);

                this.fetch();
            }
        }
    },
    model() {
        return {
            clients: [],
            contactSearch: null,
            searchMode: false,
        }
    },
    beforeUnmount() {
        this.chat.background(true)

    },
    mounted() {
        this.chat.background(false);
    },
    computed: {
        clients() {
            return this.model.clients
        },
        searchMode() {

        },
        conversations() {
            return Object.values(this.chat.conversations).filter(item => item.bind).sort((a,b) => {
                return b.lastSendAt - a.lastSendAt;
            });
        },
        clientsByConversations() {
          return this.model.searchMode ? this.pairClientsWithConversations(this.model.clients) : this.pairClientsWithConversations(this.chat.clients)
        }
    },
    methods: {
        pairClientsWithConversations(clients) {
            return clients.map((client) => {
                return {client, conversation: this.chat.conversations[client.uid] ?? null}
            }).sort((a,b) => {
                if(!!b.conversation?.countUnreadMessages === !!a.conversation?.countUnreadMessages)
                    return b.conversation?.lastSendAt - a.conversation?.lastSendAt;

                return  !!b.conversation?.countUnreadMessages - !!a.conversation?.countUnreadMessages
            })
        },
        clearSearch() {
            this.model.contactSearch = null
            this.model.searchMode = false;
        },
        seenAllConversation()
        {
            this.chat.send('message.seenAll', {});
            Object.values( this.model.conversations).forEach((item) => {
                item.countUnreadMessages = 0;
            })
        },
        fetch() {

            return this.dispatch((resolve, reject) => {

                this.model.searchMode = this.model.contactSearch?.length > 2;

                if(!this.model.searchMode) {
                    this.model.clients = [];
                    return resolve();
                }


                return this.$http.request({url: 'agent/chat-contacts', params: { contact: this.model.contactSearch, show: 50}})
                    .then((response) => {

                        this.model.clients = response.data.clients;

                    })
                    .then(resolve)
                    .catch(reject)
            });
        }
    }
}