<template>
    <div class="wniosker-form" ref="wniosker">
        <wniosker-app></wniosker-app>
    </div>
</template>

<script>
export default {
    name: "WnioskerForm"
}
</script>
<style lang="scss">
.wniosker-form {
    iframe {
        width: 100%;
        height: 70vh;
    }
}
</style>
