import model from "@/mixins/model";

export default  {
    mixins: [model],
    form: true,
    model() {
        return {
            old_password: null,
            password: null
        }
    },
    methods: {
        submit(e) {
            e.preventDefault();

            // console.log('asdasd');

            return this.dispatch( (resolve, reject) => {

                this.$http.request({url:'agent/change-password',method:'patch',data:this.model})
                    .then((response) => {

                        this.model = this.$options.model();

                        resolve(response);
                    }).catch(reject);

            }).catch(() => {});


        }
    }
}