<template>
    <div class="pagination mb-0">
        <ul class="pagination">
            <li class="page-item" v-for="(page, index) in pages" :key="index" :class="{ active: page === currentPage }">
                <button type="button" v-if="page" class="page-link" @click.prevent="toPage(page)">{{ page }}</button>
                <span class="page-link" v-else>...</span>
            </li>
        </ul>
    </div>
</template>

<script>

import component from "@/mixins/component";

export default {
    name: "VPagination",
    mixins: [component],
    props: {
        pagination: {},
        size: {default: 2}
    },
    methods: {
        toPage(page) {
            // this.model.bindQuery({ page });
            // this.model.component.fetchData();

            document.querySelector('body').scrollTop = 0;
            document.activeElement?.blur();

            this.model.toPage(page);
        }
    },
    computed: {

        currentPage() {
            return this.pagination?.current_page;
        },
        lastPage() {
            return this.pagination?.last_page;
        },
        pages() {
            if (!this.pagination || !(this.lastPage > 1)) return [];

            return [
                ...Array.range(1, this.size),
                ...Array.range(this.currentPage-this.size, this.currentPage+this.size),
                ...Array.range(this.lastPage - this.size+1, this.lastPage)
            ].filter((value,index,array) => {
                return value > 0 && value <= this.lastPage && array.indexOf(value) === index;
            }).sort((a,b) => a - b).reduce((a,current,i,array) => {

                const prev = array[i-1];

                if(current > prev+1)
                    a.push(0);

                a.push(current);

                return a;
            }, [])
        },
    }
}
</script>
