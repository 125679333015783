<template>
    <v-model.chat.conversation v-slot="{ messages, model, loading, submit, searchMode,foundTextCount,foundTextShowIndex,nextFoundText,clearSearchInMessages, prevFoundText, focusConversation }">
        <div class="col-md-6">
            <div class="border border-1 h-100 rounded-2 d-flex flex-column">
                <template v-if="messages.length">

                    <div class="chat-search-form border-bottom "  @click="focusConversation">
                        <v-icon icon="search"/>
                        <input type="text" placeholder="Szukaj w konwersacji"
                               v-model="model.searchText"
                               class="form-control form-control-lg border-0"/>
                        <div class="chat-search-navigate" v-if="searchMode">

                            <div class="btn-group" v-if="foundTextCount">
                                <button class="btn btn-light btn-xs px-0" @click="prevFoundText"><i class="icon-arrow-left-s"></i></button>
                                <div class="px-2">{{ foundTextShowIndex }} / {{ foundTextCount }}</div>
                                <button class="btn btn-light btn-xs px-0" @click="nextFoundText"><i class="icon-arrow-right-s"></i></button>
                                <button class="btn  btn-xs px-0" @click="clearSearchInMessages"><i class="icon-close-fill"></i></button>
                            </div>
                            <div v-else>
                                0 znalezionych
                            </div>

                        </div>
                    </div>
                    <div class="chat-wrapper" id="chat-scrollview" @click="openEmoji = false">
                        <div class="chat-messages" @click="focusConversation">
                            <template v-for="group in messages" :key="group.id">
                                <div class="chat-message" :class="{from: group.me, to: !group.me}">
                                    <div class="chat-message-meta">
                                        <span>{{  group.author }}</span><span class="date"> • {{ group.dateFormat }}</span>
                                    </div>
                                    <v-chat-message  v-for="message in group.messages" v-bind="message" >

                                    </v-chat-message>
                                </div>
                            </template>
                        </div>
                    </div>

                </template>

                <template v-else>
                    <div class="flex-grow-1 d-flex align-items-center justify-content-center" @click="openEmoji = false">
                        <div class="text-center">
                            <v-loading v-if="loading"/>
                            <p v-else class="fs-2 fw-600 lh-sm">Rozpocznij czat klientem: <br>
                                {{ model.client.firstName }} {{ model.client.lastName }}
                            </p>
                        </div>
                    </div>
                </template>
                <form @submit="submit" class="chat-form border-top d-flex p-2">
                    <div v-if="openEmoji" class="chat-emoji-form">
                        <EmojiPicker :native="true" @select="onSelectEmoji($event,model)" />
                    </div>
                    <v-button size="xs" @click="openEmoji = !openEmoji" color="link" icon="emotion-happy-line" class="px-1 text-center"/>
                    <div class="flex-grow-1 px-2">
                        <input type="text" required class="border-0 w-100 form-control" v-model="model.text"  @focus="focusConversation"
                               placeholder="Napisz swoją wiadomość" @click="openEmoji = false" />
                    </div>
                    <v-button submit size="xs" color="primary" label="Wyślij" icon="send-plane"/>
                </form>
            </div>
        </div>
        <div class="col-md-3">
            <v-loading v-if="loading"/>
            <template v-else>
                <h3 class="fw-600">Informacje o kliencie</h3>
                <table class="text-dark my-3">
                    <tr>
                        <th class="pe-4 py-1 fw-500">Imię</th>
                        <td class="fw-600">{{ model.client.firstName || '-' }}</td>
                    </tr>
                    <tr>
                        <th class="pe-4 py-1 fw-500">Nazwisko</th>
                        <td class="fw-600">{{ model.client.lastName || '-' }}</td>
                    </tr>
                    <tr >
                        <th class="pe-4 py-1 fw-500">PESEL</th>
                        <td class="fw-600">{{ model.client.personId || '-' }}</td>
                    </tr>
                    <tr >
                        <th class="pe-4 py-1 fw-500">Telefon</th>
                        <td class="fw-600">{{ model.client.phone || '-' }}</td>
                    </tr>
                    <tr>
                        <th class="pe-4 py-1 fw-500">Mail</th>
                        <td class="fw-600">{{ model.client.email || '-' }}</td>
                    </tr>
                </table>
            </template>
        </div>
    </v-model.chat.conversation>
</template>

<script>

import EmojiPicker from 'vue3-emoji-picker'
export default {
    name: "route-agent.chats",
    components: {EmojiPicker},
    route: {
        path: 'rozmowa/:client'
    },
    watch: {
        '$route.params.client': {
            handler() {
                this.focused = false;
            }
        }
    },
    data() {
        return {
            focused: false,
            openEmoji: false
        }
    },
    methods: {
        onSelectEmoji(emoji, model) {

            model.text ? model.text+=emoji.i : model.text=emoji.i;

        }
    }
}
</script>