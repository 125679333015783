<template>
    <div>
        <label class="form-label">{{ label }}</label>
        <multi-select
            class="form-control"
            :class="{filled}"
            ref="multiselect"
            :options="options"
            v-model="value"
            :placeholder="placeholder"
            :mode="multiple ? 'tags' : 'single'"
            :disabled="disabled"
            :can-clear="multiple"
            :required="required"
            v-bind="item"
            searchable
            :maxHeight="null"
        >
        </multi-select>
    </div>
</template>

<script>
import control from "@/mixins/control";

import multiSelect from "@vueform/multiselect";

export default {
    name: "v-control-select",
    mixins: [control],
    props: {
        multiple: { type: Boolean },
        item: { },
        options: { }
    },
    computed: {
        choiceItems() {
            return [{label: 1, value: 1},{label: 2, value: 2},{label: 3, value: 3}];
        },
        filled() {
            return this.multiple ? !!this.value?.length : !!this.value;
        }
    },
    components: {
        multiSelect
    }
}
</script>

<style scoped>

</style>