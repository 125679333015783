<template>
    <section class="h-100 d-flex flex-column">
        <div class="d-flex justify-content-between">
            <div class="hgroup">
                <h1>Komunikator</h1>
                <h2>To narzędzie pozwoli Ci na komunikację z Klientem</h2>
            </div>
        </div>

        <div class="card flex-grow-1 mb-0">
            <div class="card-body p-4">
                <div class="row h-100 chats">


                    <v-model.chat.recipients
                            v-slot="{model, searched, clientsByConversations, loading, chat, seenAllConversation}">
                        <v-loading v-if="!chat.isConnected" text="Trwa łączenie"/>
                        <template v-else>
                            <div class="col-3 d-flex flex-column">


                                <div>
                                    <v-control-input label="Rozpocznij rozmowę z klientem" v-model="model.contactSearch"
                                                     placeholder="Wpisz PESEL lub nazwisko"
                                                     icon-after="search"/>
                                </div>
                                <div class="recipients">
                                    <!--                                    <div>-->
                                    <!--                                        <span class="title">Twoje konwersacje </span>-->
                                    <!--                                        <ul>-->
                                    <!--                                            <li v-for="client in conversations">-->
                                    <!--                                                <router-link-->
                                    <!--                                                        :to="{name:'agent.chats.conversation', params:{client:client.uid}}"-->
                                    <!--                                                        class="recipient" :class="{notified: !!client.countUnreadMessages}">-->
                                    <!--                                                    <div class="recipient-avatar">-->
                                    <!--                                                        {{ client.firstName.charAt(0) }}{{ client.lastName.charAt(0) }}-->
                                    <!--                                                    </div>-->
                                    <!--                                                    <div class="recipient-name">-->
                                    <!--                                                        {{ client.firstName }} {{ client.lastName }}-->
                                    <!--                                                    </div>-->
                                    <!--                                                    <div v-if=" client.countUnreadMessages" class="recipient-badge">-->
                                    <!--                                                        <span>{{ client.countUnreadMessages < 10 ?  client.countUnreadMessages : '+9' }}</span>-->
                                    <!--                                                    </div>-->

                                    <!--                                                </router-link>-->
                                    <!--                                            </li>-->
                                    <!--                                        </ul>-->
                                    <!--                                    </div>-->
                                    <div>
                                        <v-loading v-if="loading" class="my-3"/>
                                        <template v-else>
                                            <span v-if="!model.searchMode && clientsByConversations.length" class="title">Twoje konwersacje</span>
                                            <ul>

                                                <li v-for="item in clientsByConversations">
                                                    <router-link
                                                            :to="{name:'agent.chats.conversation', params:{client:item.client.uid}}"
                                                            class="recipient"
                                                            :class="{notified: !!item.conversation?.countUnreadMessages}">
                                                        <div class="recipient-avatar">
                                                            {{
                                                                item.client.firstName.charAt(0)
                                                            }}{{ item.client.lastName.charAt(0) }}
                                                        </div>
                                                        <div class="recipient-info">
                                                        <span class="d-block recipient-name">{{ item.client.firstName }} {{
                                                                item.client.lastName
                                                            }}</span>
                                                            <span class="d-block recipient-id">{{
                                                                    item.client.personId
                                                                }}</span>
                                                        </div>
                                                        <div v-if="item.conversation?.countUnreadMessages"
                                                             class="recipient-badge">
                                                        <span>{{
                                                                item.conversation.countUnreadMessages
                                                            }}</span>
                                                        </div>
                                                    </router-link>
                                                </li>

                                            </ul>
                                        </template>


                                        <div class="alert alert-warning" v-if="!model.clients.length && !loading && model.searchMode">
                                            <span class="alert-title"> Nie znaleziono klientów</span>
                                            <span>Popraw kryteria wyszukiwania</span>
                                        </div>
                                    </div>
                                </div>
                                <!--                                <div class="d-grid gap-2 pt-3">-->
                                <!--                                    <v-button @click="seenAllConversation" size="xs" :outline="true" color="secondary"-->
                                <!--                                              label="Oznacz wszystkie jako przeczytane"></v-button>-->
                                <!--                                </div>-->


                            </div>
                            <router-view/>
                        </template>
                    </v-model.chat.recipients>

                </div>
            </div>
        </div>

    </section>
</template>

<script>

import VButton from "@/components/Button";
import VLoading from "@/components/Loading";

export default {
    name: "route-agent.chats",
    components: {VLoading, VButton},
    route: {
        path: 'komunikator',
        title: 'Komunikator',
        redirect: {name: 'agent.chats.index'}
    }
}
</script>