import model from '../mixins/model';

export default {
    mixins: [model],
    query() {
        return {
            category: null
        }
    },
    model() {
        return {
            posts: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        isActive(slug) {
            if(!this.query.category) return true;

            return this.query.category === slug;
        },
        select(name) {
            this.query.category = name;
            this.fetch()

            console.log(this);
        },
        fetch() {
            this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'post.json', name: "cms", params: this.query })
                    .then((response) => {
                        this.model.posts = response.data.posts;
                    })
                    .then((resolve))
                    .catch(reject);

            });


        },
    }
}