<template>
  <div :class="{'select-all-button-container': true, 'float-end': true, 'selected-all-checkbox': isAllGroupElementsChecked}">
      <input v-model="isChecked" @input="toggleProductsInGroup" class="form-check-input select-all-checkbox mt-0" type="checkbox" :id="'select-all-checkbox' + this.inputKey">
      <label class="form-check-label select-all-checkbox-label mt-1" :for="'select-all-checkbox' + this.inputKey">
        <span v-if="!this.isChecked">Zaznacz wszystkie w grupie</span>
        <span v-else>Odznacz wszystkie w grupie</span>
      </label>
  </div>
</template>
<script>
export default {
  name: "v-wniosker-select-all-button",
  props: {
    items: { default: null },
    group: { default: null },
  },
  data() {
    return {
      isChecked: false,
      inputKey: !this.group ? this.items.id : this.group
    }
  },
  methods: {
    toggleProductsInGroup(event) {
      this.items?.forEach((item, key) => {
        item.checked = event.target.checked;
      });
      this.items.checked = event.target.checked;
    }
  },
  computed: {
    isAllGroupElementsChecked() {
      let isChecked = true;
      this.items.forEach((item, key) => {
        if ((!item.checked || item.checked === false) && isChecked === true) {
          isChecked = false;
        }
      });
      this.isChecked = isChecked;

      return isChecked;
    }
  }
}
</script>
<style>
.select-all-button-container {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  padding: 8px 24px;
  gap: 8px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
  box-sizing: border-box;
  position: relative;
}

.select-all-checkbox {
  flex: none;
  order: 0;
  flex-grow: 0;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid #2F5CA0;
  box-shadow: inset 0px 2px 0px #DCDEE2;
  border-radius: 2px !important;
}
.select-all-checkbox:checked {
  box-shadow: none;
}
.select-all-checkbox:focus:not(:checked) {
  box-shadow: inset 0px 2px 0px #DCDEE2;
  border: 1px solid #2F5CA0;
  outline: none;
}
.select-all-checkbox-label {
  height: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #284F86;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.select-all-checkbox-label::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.selected-all-checkbox {
  border-color: #293042;
  background: #DAE6F8;
  box-shadow: 0 0 0 5px rgba(40, 79, 134, 0.2);
}
</style>