<template>
    <section class="news-post px-3 mb-3" :class="{['border-'+color]: color}">
        <div class="header py-2 px-4 " :class="{['bg-'+color]: color}">
            <div>{{ category }}</div>
            <v-date :show-icon="false" :date="date" format="DD.MM.YYYY"></v-date>
        </div>
        <div class="p-4">
            <h1 class="mb-4">{{ title }}</h1>
            <v-loading v-if="loading" color="blue" text="Trwa wczytywanie..."></v-loading>
            <iframe  ref="iframe" :srcdoc="content" @load="adjustHeight" :style="{height: 0}"></iframe>
        </div>
    </section>
</template>

<script>
export default {
    name: "v-news-post",
    props: {
        title: { type: String },
        color: { type: String },
        content: { type: String },
        category: { type: String },
        date: { type: String }
    },
    data() {
        return {
            loading: true
        }
    },
    methods: {
        adjustHeight() {

            this.$refs.iframe.style.height = this.$refs.iframe.contentWindow.document.body.scrollHeight + 'px';
            this.loading = false;
        }
    }
}
</script>

<style scoped>

</style>