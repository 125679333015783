import component from "@/mixins/component";

export default {
    mixins: [component],
    props: {
        items: { type: Array }
    },
    render() {


        if(this.model.loading && this.$slots.loading)
            return this.$slots.loading ? this.$slots.loading() : null;

        if(!this.items?.length)
            return this.$slots.empty ? this.$slots.empty() : null;

        let children = [];

        this.items.forEach((item,key) => {
            children.push(this.$slots.default({item,key}))
        });

        return children;
    },
    provide() {
        return {
            collection: this
        }
    }

}