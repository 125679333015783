<template>
    <div class="d-flex fill justify-content-center align-items-start">
        <div>
            <div class="logo py-5 text-center">

                <img alt="Loando Direct" src="../assets/loando-logo-dark.svg"/>
            </div>
            <div class="card w-400">
                <div class="card-body">
                    <div class="hgroup">
                        <h1 class="card-title">Logowanie</h1>
                        <span class="card-subtitle">Zaloguj się, aby skorzystać z panelu</span>
                    </div>

                    <v-model.login v-slot="{ model, loading, isError, isSuccess}">
                        <div v-if="isSuccess" class="alert alert-success vert-middle" role="alert">
                            <v-icon icon="check-line fs-4" /> Zalogowano pomyślnie
                        </div>
                        <div v-if="isError" class="alert alert-danger vert-middle" role="alert">
                            <v-icon icon="close-fill fs-4"/> Dane logowania są niepoprawne
                        </div>

                        <div v-if="$auth.getAuthToken()" class="alert alert-info vert-middle" role="alert">
                            <v-icon icon="pushpin-line fs-4"/> Sesja wygasła, zaloguj się ponownie
                        </div>

                        <v-control-input v-model="model.username" label="Email" placeholder="Wpisz adres email"
                                         type="text"/>
                        <v-control-password v-model="model.password" label="Hasło" placeholder="Wpisz hasło"
                                       />

                        <v-button :loading="loading" :submit="true" class="w-100" color="primary" icon="user"
                                  label="Zaloguj"/>
                    </v-model.login>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "route-login",
    route: {
        path: "/login",
        title: "Logowanie",
        // before(from, to, next) {
        //
        //     // this.$auth.check()
        //     //     .then(() => {
        //     //         console.log('zalogowany')
        //     //         next({name: 'agent'});
        //     //     })
        //     //     .catch(() => {
        //     //         console.log('nie zalogowany')
        //     //         next();
        //     //     })
        // }
    }
}
</script>

<style scoped>

</style>