<template>
    <section>
        <div class="d-flex justify-content-between">
            <div class="hgroup">
                <h1>Twoje ustawienia</h1>
                <h2>Uzupełnij swój profil i dane użytkownika</h2>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <span class="card-title">Awatar</span>

                    <v-model.user.avatar v-slot="{ model, attach, loading, isSuccess, errors}">
                        <div v-if="isSuccess" class="alert alert-success vert-middle" role="alert">
                            <v-icon icon="check-line fs-4" /> Avatar został pomyślnie zmieniony
                        </div>

                        <v-control-image :modelValue="model.avatar"
                                         :loading="loading"
                                         class="avatar-lg rounded"
                                         description="Obrazek musi mieć maksymalnie 256x256 pikseli rozmiaru oraz co najwyżej 1,5 MB"
                                         btn-color="secondary"
                                         btn-size="xs"
                                         btn-icon="refresh"
                                         :error="errors.avatar"
                                         btn-label="Zmień awatar"
                                         @attach="attach"
                                         btn-outline="true"/>

                    </v-model.user.avatar>

            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <span class="card-title">Zmień hasło</span>

                <v-model.user.password v-slot="{ model, loading, isSuccess, errors }">
                    <div v-if="isSuccess" class="alert alert-success vert-middle" role="alert">
                        <v-icon icon="check-line fs-4" /> Hasło zostało pomyślnie zmienione
                    </div>
                    <div class="d-flex my-4 align-items-center">
                        <div class="w-400">
                            <v-control-password type="password" v-model="model.old_password" :error="errors.old_password" label="Podaj obecne hasło" />
                            <v-control-password type="password" v-model="model.password" :error="errors.password" label="Podaj nowe hasło" />

                            <v-strength-password :password="model.password" />
                        </div>
                        <div class="ms-3 mb-4">
                            <v-button submit label="Zmień hasło" icon="refresh" color="primary" :loading="loading" />
                        </div>
                    </div>
                </v-model.user.password>

            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: "route-agent.settings",
    route: {
        path: 'ustawienia',
        title: 'Twoje ustawienia'
    }
}
</script>