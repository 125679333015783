
import Collection from '../core/collection';
import View from "../core/view";
import Canvas from "@/core/canvas";

export default {
    install(app) {

        // app.config.compilerOptions.isCustomElement = tag =>  tag.startsWith('wniosker-');

        app.component('v-canvas', Canvas);
        app.component('v-view', View);
        app.component('v-collection', Collection);


        const modelsContext = require.context('../models', true, /\w+\.js$/);

        modelsContext.keys().forEach((filename) => {

            const component = modelsContext(filename).default;
            const componentName = 'v-model.'+filename.substr(2, filename.length - 5).replaceAll('/','-')

            app.component(componentName, component);
        });

        const viewsContext = require.context('../views', true, /\w+\.vue$/);

        const views = [];

        viewsContext.keys().map(a => [a.substr(2, a.length-6),a]).sort().forEach(([filename,path]) => {
            const component = viewsContext(path).default

            const fullname = filename.replaceAll('/','.');
            const namespace = fullname.split('.').slice(0, -1).join('.') || null;
            const name = fullname.split('.').pop();


            views.push({fullname,name,namespace,filename,component});
        });

        const componentsContext = require.context('../components', true, /\w+\.vue$/);

        componentsContext.keys().forEach((filename) => {
            const componentName = 'v-'+filename.substr(2, filename.length - 6)
                .replace(/^[A-Z]/, (first) => first[0].toLowerCase())
                .replace(/([A-Z])/g, (letter) => `-${letter[0].toLowerCase()}`)
                .replaceAll('/','');

            const component = componentsContext(filename).default;

            app.component(componentName, component);
        });

        const sharedContext = require.context('../shared', true, /\w+\.vue$/);

        sharedContext.keys().forEach((filename) => {
            const componentName = 'v-'+filename.substr(2, filename.length - 6)
                .replace(/^[A-Z]/, (first) => first[0].toLowerCase())
                .replace(/([A-Z])/g, (letter) => `-${letter[0].toLowerCase()}`)
                .replaceAll('/','-');

            const component = sharedContext(filename).default;


            app.component(componentName, component);
        });

        app.config.globalProperties.$views = views;
    }
}