import axios from 'axios';
import Qs from "qs";

export default {

    install(app, http) {

        const clients = {};

        Object.entries(http).forEach(([name, {url}]) => {
            clients[name] = axios.create({
                baseURL: url,
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || (status >= 400 && status < 500);
                },
                paramsSerializer: function (params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                }
            })
        });


        app.provide('$http', this);
        app.config.globalProperties.$http = {
            clients,
            header(name, header, value) {
                this.client(name).defaults.headers.common[header] = value;
            },
            client(name) {
                return this.clients[name || 'default'];
            },
            url(name, path) {
                return this.client(name).defaults.baseURL+path;
            },
            parseUri(uri, params) {

                const parameters = {...app.config.globalProperties.$route.params, ...params}

                return uri.replaceAll(/:([A-Za-z][\w.]+)(\??)/g, (segment, s1, s2) => {

                    if (parameters[s1])
                        return parameters[s1] ?? '[null]';

                    if (s2 === '?')
                        return '';

                    throw Error(`Cannot find params '${segment.substr(1)}' in: ${uri}`);
                });
            },
            request(params) {
                return new Promise((resolve, reject) => {

                    let options = {
                        ...params,
                        url: this.parseUri(params.url, params.routeParams || params.params || {})
                    };

                    if(options.params)
                        options.params = Object.clearObject(options.params)

                    this.client(options.name)
                        .request(options)
                        .then((response) => {


                            if (response.status >= 200 && response.status < 300)
                                return resolve({data: response.data, code: response.status});

                            if (response.status >= 400 && response.status < 500)
                                return reject({
                                    message: response.data.message,
                                    errors: response.data.errors ?? [],
                                    code: response.status
                                });
                        })
                        .catch((e) => {
                            reject(e);
                        })
                        .finally(() => {

                        })
                });
            }
        };

        app.config.globalProperties.$http.header('default', 'X-App-Mode', 'agent');
    }

}