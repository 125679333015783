import {reactive, ref} from "vue";

export default {



    install(app, options) {

        const templatePermissions = {
            mobileEnabled: false,
            hasDocuments: false,
            viewAgents: false
        };

        app.config.globalProperties.$auth = {
            user: reactive({
                uid: null,
                username: null,
                firstName: null,
                lastName: null,
                avatar: null,
                role: null
            }),
            permissions: reactive({...templatePermissions}),
            authorized: false,
            loading: ref(false),
            appKey: ref(false),
            storage: app.config.globalProperties.$storage,
            http: app.config.globalProperties.$http,
            setAuthToken(token) {
                this.storage.set('auth', {token});
            },
            getAuthToken() {
                return this.storage.get('auth', {token: null}).token;
            },
            setPermissions(permissions) {
                Object.assign(this.permissions, {...templatePermissions}, Object.fromEntries(permissions?.map(a => [a, true]) || []));
            },
            setUser(user) {
                this.user.uid = user.uid
                this.user.username = user.username;
                this.user.avatar = user.avatar;
                this.user.firstName = user.first_name;
                this.user.lastName = user.last_name;
                this.user.role = user.role;
                this.user.qrcode = user.qrcode;
            },
            setAppKey(key) {
                this.appKey.value = key;
            },
            isLoading() {
                return this.loading.value
            },
            check() {
                this.loading.value = true


                return new Promise((resolve, reject) => {

                    if (!this.getAuthToken()) {
                        this.http.header('default', options.header.name, null);
                        return reject();
                    }

                    this.http.header('default', options.header.name, options.header.prefix + this.getAuthToken());

                    return this.http.request({url: options.uri})
                        .then((response) => {
                            this.setPermissions(response.data.permissions);
                            this.setUser(response.data.user);
                            this.setAppKey(response.data.appKey);


                            resolve();
                        })
                        .catch((e) => {


                            reject()
                        });
                }).finally(() => {
                    this.loading.value = false;
                });

            },
            login(credentials) {
                this.setAuthToken(null);

                return this.http.request({url: options.uri, method: 'post', data: credentials})
                    .then((response) => {
                        this.setAuthToken(response.data.token);
                        // this.setUser(response.data.auth.user);


                        return response;
                    })

            },
            logout() {
                this.authorized = false;
                this.user.uid = null;
                this.user.username = null;
                this.user.qrcode = null;
                this.user.avatar = null;
                this.user.fistName = null;
                this.user.lastName = null;
                this.user.permissions = {...templatePermissions};
                this.setAuthToken(null);
            }
        };
    },

}