import model from '../mixins/model';

export default {
    mixins: [model],
    model() {
        return {
            categories: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'document.json', name: "cms" })
                    .then((response) => {

                        let categories = {};
                        const category_name = 'document_type';


                        response.data.posts.forEach((doc) => {

                            doc[category_name].forEach((category) => {
                                if(!categories[category.id]) {
                                    categories[category.id] = {...category};
                                    categories[category.id].documents = [];
                                }
                                categories[category.id].documents.push(doc);
                            });


                        });

                        // console.dir(Object.values(categories));

                        this.model.categories = Object.values(categories);
                    })
                    .catch(reject);

            });


        },
    }
}