export default {
    props: {
        error: {},
        label: {type: String},
        name: { type: String },
        size: { type: String },
        placeholder: { type: String },
        slim: {type:Boolean},
        modelValue: {},
        hint: { type: String },
        disabled: { type: Boolean },
        required: { type: Boolean },
        readonly: { type: Boolean }
    },
    methods: {
        getValue() {
            return this.value;
        }
    },
    computed: {
        id() {
            return `control${this._.uid}`;
        },
        value: {
            set($value) {
                this.$emit('update:modelValue', $value);
            },
            get() {
                return this.modelValue
            }
        }
    }
}