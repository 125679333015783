import model from '../mixins/model';

export default {
    mixins: [model],
    model() {
        return {
            client: {},
            agent: null
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {

            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'clients/:client/details' })
                    .then((response) => {

                        this.model.client = response.data.details;
                        this.model.agent = response.data.details.agent;
                    })
                    .then(resolve)
                    .catch(reject);

            });


        },
    }
}