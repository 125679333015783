export default  {

    install(app) {
        app.config.globalProperties.$storage = {
            storage: window.localStorage,
            set(key, item) {
                this.storage.setItem(key, window.btoa(encodeURIComponent(JSON.stringify(item))));
            },
            has(key) {
                return this.storage.getItem(key);
            },
            get(key, df) {
                if (this.has(key))
                    return JSON.parse(decodeURIComponent(window.atob(this.storage.getItem(key))));

                return df;
            },
            push(key, item) {
                let i = this.get(key, []);
                i.push(item);
                this.set(key, i);
            },
            pull(key) {
                let i = this.get(key, []);
                let k = i.pop();
                this.set(key, i);
                return k;
            }
        }
    }

}
