<template>
    <div class="card">
        <div class="card-body">
            <div :class="{'card-title': true, 'mb-5': !group.label_agent}">{{ group.label_agent }} <v-wniosker-select-all-button :items="group.elements" :group="group.id"/></div>
            <div class="row mt-3">
                <v-wniosker-products :products="group.elements">
                    <div class="col-md-3"><slot/></div>
                </v-wniosker-products>
            </div>
        </div>
    </div>
</template>
<script>
import VWnioskerSelectAllButton from "@/shared/wniosker/select.all.button.vue";

export default {
  name: "v-wniosker-group",
  components: {VWnioskerSelectAllButton},
  props: {
      group: { required: true}
  }
}
</script>
