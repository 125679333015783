<template>
    <i class="icon" :class="{['icon-'+icon]: true, [$props['class']]: !!$props['class']}" />
</template>

<script>
export default {
    name: "v-icon",
    props: {
        icon: { required: true }
    }

}
</script>
