import { h, resolveComponent, provide } from 'vue';
import { viewDepthKey } from 'vue-router';

export default {
    name: "v-view",
    props: {
        name: { type: String, default: 'default' },
        layer: Number
    },
    setup(props) {
        provide(viewDepthKey, props.layer);
    },
    render() {

        return h(resolveComponent('router-view'),{name: this.name});
    }
}
