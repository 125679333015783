<template>
    <div class="loading text-center d-flex justify-content-center align-items-center">
        <div class="spinner-border" :class="{['text-'+color]: !!color, 'spinner-border-sm': small}"  role="status">
            <span class="visually-hidden"></span>
        </div>
        <div class="ms-2" :class="{['text-'+color]: !!color}">
            {{ text }}...
        </div>
    </div>
</template>

<script>
export default {
    name: "v-loading",
    props: {
        small: {type: Boolean, default: false},
        color: {type: String, default: 'secondary'},
        text: {type: String, default: 'Trwa wczytywanie'}
    }
}
</script>
