<template>
    <component :is="tag" v-bind="attr" :class="classes" @click="blur">
        <template v-if="$slots.default"><slot/></template>
        <template v-else>
            <div v-if="loading" class="spinner-border spinner-border-sm me-2"   role="status">
                <span class="visually-hidden"></span>
            </div>
            <v-icon v-else-if="icon" :icon="icon" :class="{'pe-1': !!label}" />
            <span v-if="label">{{ label }}</span>
        </template>
    </component>
</template>

<script>
import VLoading from "@/components/Loading";
export default {
    name: "v-button",
    components: {VLoading},
    props: {
        btn: { type: Boolean, default: true },
        color: { type: String },
        class: { type: String },
        icon: { type: String },
        iconActive: { type: String },
        size: { type: String },
        block: { type: Boolean },
        outline: { type: Boolean },
        label: { type: String },
        href: { type: String },
        download: { type: String },
        route: { type: Object },
        bold: { type: Boolean },
        target: { type: String },
        submit: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        removeBorder: { type: Boolean, default: false }
    },
    methods: {
       blur() {
           document.activeElement?.blur()
       }
    },
    computed: {
        btnSizeClass() {
            return ["btn", this.size].join("-");
        },
        btnColorClass() {
            return ["btn", this.outline ? "outline" : null, this.color]
                .filter((x) => !!x)
                .join("-");
        },
        classes() {
            return {
                btn: this.btn,
                [this.btnColorClass]: !!this.color,
                "btn-block": this.block,
                [this.btnSizeClass]: this.size,
                [this.class]: !!this.class,
                "text-bold": this.bold,
                "border": this.removeBorder
            };
        },
        tag() {

            if(this.route)
                return 'router-link';

            if(this.href)
                return 'a';

            return 'button';
        },
        attr() {

            if(this.route)
                return {to: this.route};

            if(this.href)
                return {href: this.href, download: this.download};

            return {
                type: this.submit ? 'submit' : 'button'
            };
        }
    }
}
</script>

<style scoped>

</style>