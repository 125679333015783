<template>
    <div class="chat-message-content" :class="{unseen: !viewAt}" :title="'Wysłano '+dateFormat.toLowerCase()">
        <p v-if="text">
            <component :is="textView"/>
        </p>

        <div v-if="images.length" class="chat-message-images">
            <div v-for="image in images" class="chat-message-image">

                <v-preview-image :url="image" v-slot="{open}">
                    <img :src="image" alt="Załącznik" @click="open"/>
                </v-preview-image>

            </div>
        </div>

    </div>
</template>

<script>
import {h} from "vue";

export default {
    name: "message",
    inheritAttrs: false,
    on: ['seen'],
    inject: {
        chat: {from: 'chat'},
        chatConversation: {from: 'chatConversation'}
    },
    computed: {
        textView() {
            if (!this.foundSearchText.count)
                return () => h('span', {}, [this.text])

            return () => this.text.split(this.foundSearchText.regex).map((text, i) => {

                const index = i % 2 === 1 ? this.foundSearchText.index + (((i - 1) / 2) + 1) : null;

                return h('span', {id: index ? `search-highlight-${index}` : null,
                    class: {
                        'search-pointer': index === this.chatConversation.foundTextShowIndex,
                        'search-highlight': i % 2 === 1
                    }
                }, [text])
            });
        }
    },
    props: {
        id: {},
        dateFormat: {},
        foundSearchText: {type: Object},
        viewAt: {type: Date},
        text: {type: String, default: null},
        images: {type: Array, default: () => []}
    }
}
</script>