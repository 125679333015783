<template>
    <section>
        <v-model.dashboard v-slot="{ model, query, fetch }">
            <div class="d-flex justify-content-between">
                <div class="hgroup" v-if="$auth.user">
                    <h1>Cześć {{ model.userData.firstName }}!</h1>
                    <h2>To Twoje centrum dowodzenia - podsumowanie działań z miesiąca
                        <strong>{{ model.currentMonth.label }}</strong></h2>
                </div>

                <div class="d-flex">
                    <div class="w-200 me-2" v-if="model.currentMonth">
                        <v-control-select label="Wybierz miesiąc"  :item="{label:'text', value:'value'}" :options="monthOptions" v-model="query.month" />
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md">
                    <div class="row">
                        <div class="col-md">
                            <div class="card">
                                <div class="card-body card-total">
                                    <div class="d-flex justify-content-between">
                                        <div class="widget">
                                            <span class="number">{{
                                                    Number(model.applications.current || 0).toPrettyFormat()
                                                }}
                                                <v-icon
                                                    :icon="parseInt(model.applications.diff) > 0 ? 'arrow-chevron-up' : 'arrow-chevron-down'"/></span>
                                            <span class="sub">Złożonych <br/> wniosków</span>
                                        </div>
                                        <div class="icon">
                                            <img alt="#" src="../assets/card-icon-1.svg"/>
                                        </div>
                                    </div>

                                    <div class="mt-2">
                                        <v-indicator :date="model.previousMonth.val" :number="model.applications.diff"
                                                     unit="%"/>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card">
                                <div class="card-body card-total">
                                    <div class="d-flex justify-content-between">
                                        <div class="widget">
                                            <span class="number">{{ Number(model.offers.current || 0).toPrettyFormat() }}
                                                 <v-icon :icon="parseInt(model.offers.diff) > 0 ? 'arrow-chevron-up' : 'arrow-chevron-down' "/></span>
                                            <span class="sub">Otrzymanych <br/>   ofert</span>
                                        </div>
                                        <div class="icon">
                                            <img alt="#" src="../assets/card-icon-1.svg"/>
                                        </div>
                                    </div>

                                    <div class="mt-2">
                                        <v-indicator :date="model.previousMonth.val" :number="model.offers.diff"
                                                     unit="%"/>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md">
                            <div class="card">
                                <div class="card-body card-total">
                                    <div class="d-flex justify-content-between">
                                        <div class="widget">
                                            <span class="number">{{ Number(model.clients.current || 0).toPrettyFormat() }}
                                               <v-icon :icon="parseInt(model.clients.diff) > 0 ? 'arrow-chevron-up' : 'arrow-chevron-down' "/></span>
                                            <span class="sub">Pozyskanych <br/>   klientów</span>
                                        </div>
                                        <div class="icon">
                                            <img alt="#" src="../assets/card-icon-2.svg"/>
                                        </div>
                                    </div>


                                    <div class="mt-2">
                                        <v-indicator :date="model.previousMonth.val" :number="model.clients.diff"
                                                     unit="%"/>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card card-gold">
                                <div class="card-body card-total">
                                    <div class="d-flex justify-content-between">
                                        <div class="widget">
                                            <span class="number">{{ Number(model.products.current || 0).toPrettyFormat() }}
                                                <v-icon :icon="parseInt(model.products.diff) > 0 ? 'arrow-chevron-up' : 'arrow-chevron-down' "/>
                                            </span>
                                            <span class="sub">Sprzedanych <br/>  produktów</span>
                                        </div>
                                        <div class="icon">
                                            <img alt="#" src="../assets/card-icon-3.svg"/>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <v-indicator :date="model.previousMonth.val" :number="model.products.diff"
                                                     unit="%"/>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md d-flex flex-column">
                    <div class="card flex-grow-1">
                        <div class="card-body">
                            <span class="card-title">Jak wygląda Twoja sprzedaż</span>
                            <span class="card-subtitle">Szczegółowy wykres miesiąca</span>

                            <div class="mt-4">

                                <v-charts :data="model.charts.main" :options="{responsive: true, maintainAspectRatio: false, title: { display: false }, legend: {
                                position: 'bottom', align: 'start', labels: { fontColor: '#284F86', fontSize: 12,
                                padding: 20 }, custom: false } }" type="bar"/>

                            </div>
                        </div>


                    </div>

                </div>

            </div>
            <div class="row">
                <div class="col-md-3 d-flex flex-column h-400">
                    <section class="card flex-grow-1 ">
                        <div class="card-body">
                            <span class="card-title">Typy produktów</span>
                            <span class="card-subtitle">Jakie produkty kupowali klienci w danym miesiącu?</span>

                            <div class="mt-4">
                                <v-charts :data="model.charts.productTypes"
                                          :options="{maintainAspectRatio:!1,layout:{padding:{top: 20}}, title:{display: !1},scales:{yAxes:[{display:!1}],xAxes:{ticks:{fontColor:'#284F86'}}},legend:{position:'bottom',align:'start',labels:{fontColor:'#284F86',padding:20},custom:!1}}"
                                          type="doughnut" value-type="percent"/>
                            </div>


                        </div>
                    </section>
                </div>
                <div class="col-md-3 d-flex flex-column h-400">
                    <section class="card flex-grow-1 ">
                        <div class="card-body">
                            <span class="card-title">Rozkład kwotowy</span>
                            <span
                                class="card-subtitle">Jakie kwoty otrzymywali klienci w danym miesiącu?</span>
                            <div class="mt-4">
                                <v-charts :data="model.charts.loanAmountRanges"
                                          :options="{maintainAspectRatio:!1,layout:{padding:{top:0}},title:{display:!1},legend:{display:!1},scales:{xAxes:[{gridLines:{display:!1},ticks:{display:!1},scaleLabel:{display:!0,labelString:'ilo\u015B\u0107 klient\xf3w',fontColor:'#284F86'}}],yAxes:[{ticks:{fontColor:'#284F86'}}]}}"
                                          type="horizontalBar"/>
                            </div>

                        </div>
                    </section>
                </div>
                <div class="col-md-6 d-flex flex-column h-400">
                    <section class="card flex-grow-1  overflow-auto">
                        <div class="card-body pt-0">
                            <div class="position-sticky bg-white top-0 py-3">
                                <span class="card-title">Sprzedane produkty w danym miesiącu</span>
                                <span class="card-subtitle">Z jakich firm skorzystali Klienci w danym miesiącu?</span>
                            </div>

                            <div>
                                <v-table v-slot="{ item }" :items="model.soldProducts" class="mb-0">
                                    <v-column label="Oferta">
                                        <img :alt="item.name" :src="item.url" class="logo-image-min2"/>
                                    </v-column>
                                    <v-column label="Sprzedanych">
                                        <span class="fs-5 text-primary vert-middle "><v-icon
                                            :icon="item.icon"/> {{ item.sold }} szt</span>
                                    </v-column>
                                    <v-column label="Udział w portfelu">
                                        <span class="fs-5 text-primary vert-middle"><v-icon
                                            :icon="item.icon"/> {{ item.in_wallet }}</span>
                                    </v-column>
                                    <v-column label="Zmiana procentowa">
                                        <v-indicator :number="item.change_percent" unit="%"/>
                                    </v-column>
                                </v-table>
                            </div>
                        </div>
                    </section>
                </div>

            </div>


        </v-model.dashboard>
    </section>

</template>

<script>

import VIndicator from "@/components/Indicator";
import VControlSelect from "@/components/Control/Select";
import agents from "@/models/options/agents";

export default {
    name: "route-agent.index",
    components: {VControlSelect, VIndicator},
    mixins: [agents],
    route: {
        title: 'Podsumowanie',
        path: 'index'
    },
    computed: {
        monthOptions() {
            return async () => {

                return this.$http.request({url:'months'})
                    .then((response) => {
                        return response.data.dates
                    });

            }
        },

    }
}
</script>
