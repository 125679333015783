<template>
<!--    <div>-->
<!--        Trwa uwierzytelnianie-->
<!--    </div>-->
</template>

<script>
export default {
    name: "route-index",
    route: {
        path: '/',
        redirect: '/login'
    }
}
</script>

<style scoped>

</style>