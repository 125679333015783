<template>
    <div :class="{'mb-3':!slim}">
        <label class="form-label">{{ label }}</label>
        <div class="position-relative">
            <v-icon :icon="iconAfter" :class="{'form-after-icon': !!iconAfter}"/>
            <input :type="type" class="form-control" :placeholder="placeholder" v-model="value" :class="{filled: !!value}">
        </div>
        <p class="form-control-error text-danger" :key="msg" v-for="msg in error">błąd: {{ msg }}</p>
    </div>
</template>

<script>
import control from "@/mixins/control";

export default {
    name: "v-control-input",
    mixins: [control],
    props: {
        type: {default: 'text'},
        iconAfter: {default: null}
    },
}
</script>

<style scoped>

</style>