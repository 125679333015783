<template>
  <div class="wniosker-offers">
    <div v-if="Object.keys(getGroupsFromOffers).length !== 0" v-for="(items, key) in getGroupsFromOffers">
      <span class="d-block fw-600 mb-4"><v-wniosker-offer-label :offerGroupName="key"/> <v-wniosker-select-all-button :items="items" :group="key"/></span>
      <div class="row">
        <v-collection :items="items" :group="key">
          <template v-slot:default="{item}">
            <component :is="'v-wniosker-offer-' + key.toLowerCase()" :offer="item"/>
          </template>
          <template v-slot:loading>
            <div class="py-3">
              <v-loading color="secondary" text="Trwa wczytywanie..."/>
            </div>
          </template>
        </v-collection>
      </div>
    </div>
    <div v-else class="d-flex">
      <div class="col-md-3">
        <div class="product mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="gold" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="product blur  mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="light" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="product blur  mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="light" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="product blur  mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="light" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VWnioskerSelectAllButton from "@/shared/wniosker/select.all.button.vue";
import VWnioskerOfferLabel from "@/shared/wniosker/offers/offer.label.vue";
import VWnioskerOfferOffernonbank from "@/shared/wniosker/offers/offer.offerNonbank.vue";
import VWnioskerOfferOfferpayday from "@/shared/wniosker/offers/offer.offerPayday.vue";
import VWnioskerOfferOther from "@/shared/wniosker/offers/offer.other.vue";
import { OffersLabelsEnum } from "@/enums/offers/offers.label.enum";
import VWnioskerOfferOfferbank from "@/shared/wniosker/offers/offer.offerBank.vue";
import VWnioskerOfferOfferlead from "@/shared/wniosker/offers/offer.offerLead.vue";
import VWnioskerOfferOfferleadnonbank from "@/shared/wniosker/offers/offer.offerLeadNonbank.vue";
import VWnioskerOfferOfferleadbank from "@/shared/wniosker/offers/offer.offerLeadBank.vue";
import VWnioskerOfferOfferaff from "@/shared/wniosker/offers/offer.offerAff.vue";

export default {
  name: "v-wniosker-offers",
  components: {
    VWnioskerOfferOfferaff,
    VWnioskerOfferOfferleadbank,
    VWnioskerOfferOfferleadnonbank,
    VWnioskerOfferOfferlead,
    VWnioskerOfferOfferbank,
    VWnioskerOfferOther,
    VWnioskerOfferOfferpayday,
    VWnioskerOfferOffernonbank, VWnioskerOfferLabel, VWnioskerSelectAllButton},
  emits: ['refresh'],
  props: {
    offer: {},
    root: {type: Boolean, default: true},
  },
  data() {
    return {
      sortedOffers: []
    }
  },
  computed: {
    getGroupsFromOffers() {
      let groups = {};
      this.offer?.elements?.map((item) => {
        if (!item.groupKey || !OffersLabelsEnum[item.groupKey]) {
          if (!groups['other']) {
            groups['other'] = [];
          }
          groups['other'].push(item);
        } else {
          if (!groups[item.groupKey]) {
            groups[item.groupKey] = [];
          }
          groups[item.groupKey].push(item);
        }
      });

      return groups;
    }
  }
}
</script>

<style scoped>

</style>