import {createRouter, createWebHistory, useRoute} from "vue-router";
import {h, reactive, watch} from "vue";
import qs from "qs";

export default {
    install(app) {


        const routes = [{
            path: '/:catchAll(.*)', name: 'loading', component: h('div', 'not found')
        }]

        const globalQueries = {
            query: {},
            params: reactive({}),
            set: function (name, value) {
                this.query[name] = value;

                router.replace({query: {...router.currentRoute.value.query, [name]: value}}).then(() => {
                    this.params[name] = value;
                });
            }

        };

        const history = createWebHistory(process.env.BASE_URL);
        const router = createRouter({

            parseQuery(query) {

                return query ? qs.parse(query) : {};

            },
            stringifyQuery(query) {

                return (query && Object.values(query).length) ? qs.stringify(query) : '';

            },
            history, routes, linkActiveClass: "active", linkExactActiveClass: "exact-active"
        });

        router.beforeEach((to, from, next) => {

            const clearQuery = Object.clearObject({...to.query, ...globalQueries.query});

            if(Object.isEqual(to.query, clearQuery)) return next();

            to.query = clearQuery;

            return next(to);
        })


        app.config.globalProperties.$globalQueries = globalQueries;

        app.config.globalProperties.$views.forEach((view) => {

            if (!view.component.route) return;

            // console.log(view);
            const route = {
                name: view.fullname, path: view.component.route.path, meta: {
                    title: view.component.route.title, namespace: view.namespace, name: view.name
                }, redirect: view.component.route.redirect, beforeEnter: (to, from, next) => {

                    if (view.component.route.before) return view.component.route.before.apply(app.config.globalProperties, [to, from, next]);

                    return next();
                }, children: [], components: {
                    [view.component.view ?? 'default']: view.component
                }
            }


            view.namespace ? router.addRoute(view.namespace, route) : router.addRoute(route);

        });

        app.config.globalProperties.$reloadRouter = () => router.replace(history.location);

        // watch()

        app.use(router);

        watch(router.currentRoute, (route) => {

            let titles = [];

            route.matched.forEach((item) => {
                if(item.meta.title)
                    titles.push(item.meta.title);
            });

            titles = titles.reverse();
            titles.push(this.title)

            document.title = titles.join(' - ');
        });
    }
}