import {h} from "vue";

export default {
    data() {
        return {
            model: this.$options.model.call(this),
            query: this.$options.query ? this.$options.query() : {},
            loading: false,
            errors: [],
            isError: false,
            isSuccess: false,
            pagination: {}
        }
    },
    inject: ['snack'],
    provide() {
        return {
            model: this
        }
    },
    computed: {
        cleanQuery() {

            return this.cleanObject(this.query);

        }
    },
    methods: {
        bindQuery() {
            Object.assign(this.query, this.$route.query);
        },
        clearQuery() {
            this.query = this.$options.query();
            this.fetch();
        },
        search() {
            this.query.page = 1;
            this.fetch();
        },
        toPage(page) {

            this.query.page = page;
            this.fetch();
        },
        normalizePath(route) {

            if (route.path.indexOf('?') < 0) return route;

            const path = route.path.substr(0, route.path.indexOf('?'));

            const search = new URLSearchParams(route.path.substr(route.path.indexOf('?') + 1));
            const query = Object.fromEntries(search.entries());

            return {path, query};
        },
        dispatch(callback) {

            this.loading = true;
            this.isError = false;
            this.isSuccess = false;
            this.errors = {};

            const promise = new Promise(callback);


            promise.catch(e => {
                this.isError = true;
                this.errors = e.errors;

                if (e.code === 401) {
                    this.$router.push({name: 'index'});
                }


            }).finally(() => {
                this.loading = false;
            });

            return promise.then((e) => {

                //

                this.isSuccess = true;
                return e;
            })

        },
        fetch() {

        },
        submit() {

        }
    },
    render() {

        const children = this.$slots.default(this);

        return this.$options.form ? h('form', {ref: 'form', onSubmit: this.submit}, children) : children;
    }
}