import model from '../mixins/model';

export default {
    mixins: [model],
    model() {
        return {
            data: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {

            return this.dispatch((resolve, reject) => {

                this.$http.request({ url: 'clients/:client/data' })
                    .then((response) => {

                        this.model.data = response.data.data;
                    })
                    .then(resolve)
                    .catch(reject);

            });


        },
    }
}