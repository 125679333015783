<template>
    <button class="news-category-item p-3" type="button" :class="{['bg-'+color]: color, active}">
        <span class="title"> {{ title }}</span>
    </button>
</template>

<script>
import control from "@/mixins/control";

export default {
    name: "v-news-category",
    mixins: [control],
    props: {
        active: { type: Boolean },
        title: { type: String },
        color: { type: String },
        slug: { type: String }
    }
}
</script>

<style scoped>

</style>