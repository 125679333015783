import model from '../../mixins/model';

export default {
    mixins: [model],
    model() {
        return {
            from_at: null,
            created_at: null,
            leadFormFinished: null,
            leadAgreementsSent: null,
            leadAgreementsChecked: null,
            leadProcessingStarted: null,
            leadAccepted: null,
            leadRedirected: null,
            leadPaid: null,
            actionDontCall: null,
            actionNotAnsweredThePhone: null,
            actionPleaseCallBack: null,
            actionNote: null,
            actionDecisionUndertaking: null,
            actionVoiceMail: null,
            personId: { equal: null },
            email: { equal: null },
            phone: { equal: null },
            firstName: { equal: null },
            lastName: { equal: null },
            'status_id': { in: [] }
        }
    },
    mounted() {
        // this.fetch();
    },
    methods: {
        // fetch() {
        //     this.dispatch((resolve, reject) => {
        //
        //         this.$http.request({ url: 'products'})
        //             .then((response) => {
        //                 this.model.products = response.data.data;
        //                 this.model.agreements = response.data.appForm.agreements;
        //             })
        //             .then(resolve)
        //             .catch(reject);
        //
        //     });
        //
        //
        // },
    }
}