import model from '../mixins/model';

export default {
    mixins: [model],
    form: true,
    model() {
        return {
            username: null,
            password: null,
            remember: false
        }
    },
    methods: {
        submit(e) {
            e.preventDefault();

            this.dispatch((resolve,reject) => {

                // console.log(this.model);

                this.$auth.login(this.model).then(resolve).catch(reject);

            }).then(() => {


                this.$router.push({name:"agent"});
            }).catch(() => {});
        }
    }
}