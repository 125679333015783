<template>
    <div ref="wrapper" class=" position-relative">

        <v-loading v-if="model.loading" text="Trwa wczytywanie..." color="secondary my-2"></v-loading>
        <div v-show="!model.loading" class="charts">

            <canvas  ref="chart"></canvas>
        </div>
<!--        <div id="custom-legend" v-html="customLegend"></div>-->
    </div>
</template>

<script>


import Chart from "chart.js";
import colors from "../plugins/colors";
import component from "@/mixins/component";

export default {
    name: "v-chart",
    mixins: [component],
    props:  {
        title: { type: String },
        type: { type: String, default: "bar" },
        options: { type: Object, default: () => ({}) },
        scales: { type: Object, default: () => ({}) },
        stacked: { type: Boolean, default: false },
        legend: { type: Object, default: () => ({}) },
        valueType: { type: String, default: "text" },
        data: {}
    },
    data() {
        return {
            eXY: null,
            chart: null,
            canvasCtx: null,
            customLegend: null,
        };
    },
    watch: {
        data: {
            handler() {
                this.redraw();
            },
            deep: true,
        },
    },
    mounted() {
        this.canvasCtx = this.$refs.chart.getContext("2d");
        this.redraw();
    },
    methods: {
        zoom(e) {
            console.log(this.chart);
            const ticks = this.chart.options.scales.yAxes[0].ticks;

            let min = ticks.min || 0;
            let max = ticks.max || 0;

            const kY = Math.floor(e.deltaY / 25);

            let lmin = min - kY;
            let lmax = max + kY;

            if (lmin < 0 || lmin > lmax) return;

            this.chart.options.scales.yAxes[0].ticks.min = lmin;
            this.chart.options.scales.yAxes[0].ticks.max = lmax;
            this.chart.update();
        },
        pan(e) {
            if (this.eXY) {
                // const dX = this.eXY[0] - e.offsetX;
                const dY = this.eXY[1] - e.offsetY;

                const ticks = this.chart.options.scales.yAxes[0].ticks;

                let min = ticks.min || 0;
                let max = ticks.max || 0;

                let lmin = min - dY;
                let lmax = max - dY;

                if (lmin >= 0) {
                    this.chart.options.scales.yAxes[0].ticks.min = lmin;
                    this.chart.options.scales.yAxes[0].ticks.max = lmax;
                    this.chart.update();
                    this.eXY = null;
                    return;
                }
            }
            this.eXY = e.offsetX && e.offsetY ? [e.offsetX, e.offsetY] : null;
        },
        color(color) {
            return String(color).charAt(0) !== "#"
                ? {
                    backgroundColor: colors[color]?.darken2,
                    borderColor: colors[color]?.base,
                    borderWidth: 2,
                }
                : {
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 2,
                };
        },

        redraw() {
            if (!this.data || !this.data.data) return;

            if (this.chart) this.chart.destroy();

            this.chart = new Chart(this.canvasCtx, {
                type: this.data.type || this.type,

                data: {
                    labels: this.data.data.labels,
                    datasets: this.data.data.datasets.map((dataset) => {
                        let { backgroundColor, borderColor, borderWidth } =
                            this.color(dataset.color);

                        return {
                            backgroundColor,
                            borderColor,
                            borderWidth,
                            ...dataset,
                        };
                    }),
                },

                options: {
                    // maintainAspectRatio: false,
                    animation: {
                        duration: 0, // general animation time
                    },
                    hover: {
                        animationDuration: 0, // duration of animations when hovering an item
                    },
                    responsiveAnimationDuration: 0,
                    legend: {
                        position: "top",
                        ...this.legend,
                    },
                    title: {
                        display: true,
                        text: this.data.title || this.title,
                    },
                    tooltips: {
                        mode: "nearest",
                        callbacks: {
                            label: (tooltipItem, data) => {
                                let dataset =
                                    data.datasets[tooltipItem.datasetIndex];

                                let currentValue =
                                    dataset.data[tooltipItem.index];

                                return (
                                    currentValue +
                                    (this.valueType === "percent" ? "%" : "")
                                );
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            formatter: (value, ctx) => {
                                let sum = ctx.dataset._meta[0].total;
                                return ((value * 100) / sum).toFixed(2) + "%";
                            },
                            color: "#000",
                        },
                    },
                    scales: this.data.scales || {
                        xAxes: [
                            {
                                display: this.type !== "radar",
                                stacked: this.stacked,
                                scaleLabel: {
                                    display: !!this.scales.xLabel,
                                    labelString: this.scales.xLabel,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                display: this.type !== "radar",
                                stacked: this.stacked,
                                scaleLabel: {
                                    display: !!this.scales.yLabel,
                                    labelString: this.scales.yLabel,
                                },
                            },
                        ],
                    },
                    ...this.options,
                    ...this.data.options,
                },
            });

            if (this.options.legend.custom)
                this.customLegend = this.chart.generateLegend();
        },
    },

}



Chart.elements.Rectangle.prototype.draw = function () {
    let ctx = this._chart.ctx;
    let vm = this._view;
    let left, right, top, bottom, signX, signY, borderSkipped, radius;
    let borderWidth = vm.borderWidth;
    // Set Radius Here
    // If radius is large enough to cause drawing errors a max radius is imposed
    let cornerRadius = 20;

    if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || "bottom";
    } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || "left";
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        let barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        let halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        let borderLeft =
            left + (borderSkipped !== "left" ? halfStroke * signX : 0);
        let borderRight =
            right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
        let borderTop =
            top + (borderSkipped !== "top" ? halfStroke * signY : 0);
        let borderBottom =
            bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
        }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    let corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
    ];

    // Find first (starting) corner with fallback to 'bottom'
    let borders = ["bottom", "left", "top", "right"];
    let startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
        startCorner = 0;
    }

    function cornerAt(index) {
        return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    let corner = cornerAt(0);
    let width, height, x, y, nextCornerId;
    let x_tl, x_tr, y_tl, y_tr;
    let x_bl, x_br, y_bl, y_br;
    ctx.moveTo(corner[0], corner[1]);

    for (let i = 1; i < 4; i++) {
        corner = cornerAt(i);
        nextCornerId = i + 1;
        if (nextCornerId === 4) {
            nextCornerId = 0;
        }

        width = corners[2][0] - corners[1][0];
        height = corners[0][1] - corners[1][1];
        x = corners[1][0];
        y = corners[1][1];

        radius = cornerRadius;

        // Fix radius being too large
        if (radius > Math.abs(height) / 2) {
            radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
            radius = Math.floor(Math.abs(width) / 2);
        }

        if (height < 0) {
            // Negative values in a standard bar chart
            x_tl = x;
            x_tr = x + width;
            y_tl = y + height;
            y_tr = y + height;

            x_bl = x;
            x_br = x + width;
            y_bl = y;
            y_br = y;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);
            ctx.lineTo(x_br - radius, y_br);
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
            ctx.lineTo(x_tr, y_tr + radius);
            ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
            ctx.lineTo(x_tl + radius, y_tl);
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
            ctx.lineTo(x_bl, y_bl - radius);
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
        } else if (width < 0) {
            // Negative values in a horizontal bar chart
            x_tl = x + width;
            x_tr = x;
            y_tl = y;
            y_tr = y;

            x_bl = x + width;
            x_br = x;
            y_bl = y + height;
            y_br = y + height;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);
            ctx.lineTo(x_br - radius, y_br);
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
            ctx.lineTo(x_tr, y_tr + radius);
            ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
            ctx.lineTo(x_tl + radius, y_tl);
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
            ctx.lineTo(x_bl, y_bl - radius);
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
        } else {
            //Positive Value
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(
                x + width,
                y + height,
                x + width - radius,
                y + height
            );
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
        }
    }

    ctx.fill();
    if (borderWidth) {
        ctx.stroke();
    }
};
</script>
<style>
.charts {
    height: 40vh;
    max-height: 250px;
}
.charts canvas {
    position: relative;
}

#custom-legend {
    position: absolute;
    bottom: -10px;
}
#custom-legend ul {
    padding: 0;
}
#custom-legend li {
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
}
#custom-legend li span {
    display: inline-block;
    width: 25px;
    height: 15px;
    margin-right: 5px;
    border-radius: 3px;
    vertical-align: -10%;
}
</style>