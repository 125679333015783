<template>
    <div class="w-100 h-100 position-relative overflow-hidden iframe-zoom">
        <iframe :srcdoc="content" class="position-absolute overflow-hidden" :style="{transform:`scale(${scale})`, 'transformOrigin': 'center 0', width: width+'px', height: height+'px', marginLeft: -(width/2) + 'px'}"/>
    </div>
</template>

<script>
export default {
    name: "Iframe",
    props: {
        content: {},
        width: {},
        height: {},
    },
    data() {
        return {
            scale: 0
        }
    },
    mounted() {
        this.scaling();

        window.addEventListener('resize', () => this.scaling());
    },
    methods: {
        scaling() {
            const scaleY = this.$el.offsetHeight/this.height;
            const scaleX = this.$el.offsetWidth/this.width;

            this.scale = scaleX < scaleY ? scaleX : scaleY;
        }
    }
}
</script>